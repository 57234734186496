.bullet-description {
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        row-gap: 60px;

        @include device-lg {
            row-gap: 32px;
        }
    }

    &__content {
        width: 50%;
        max-width: 448px;
        display: flex;

        &.--content-expanded {
            width: 100%;
            max-width: 80%;

            .bullet-description__text {
                max-width: 100%;
                text-wrap: pretty;
            }
        }
    }

    &__text {
        margin-left: 24px;
        max-width: 448px;
        width: 100%;

        p {
            font-weight: 400;
        }
    }

    .green-circle {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        background-color: $green;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;

        p {
            color: $white;
            text-align: center;
            @include font-size(20, 27.28, 700, 0)
        }
    }

    @include device-lg {
        flex-direction: column;

        &__content {
            width: 100%;

            .h5 {
                @include font-size(20, 27.3, 400, -2);
            }
        }

        &__text {
            max-width: 100%;
        }
    }
}