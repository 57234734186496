#modal-detail {
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, .8);
    height: 100%;
    width: 100%;
    border: none;
    overflow: auto;
    .modal-detail__remove-btn {
        display: none;
    }
    &.--selected {
        .modal-detail__remove-btn {
            display: block;
        }
        .cotizar {
            display: none;
        }
    }
    &.--open {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .modal__box {
        background-color: $white;
        border: 1px solid $medium-gray;
        border-radius: 10px;
        max-width: 980px;
        width: 100%;
        padding: 30px;
        position: relative;
        margin: auto;
        
        .modal__close {
            color: $green;
            position: absolute;
            top: 30px;
            right: 30px;
        }

        &__content {
            .product {
                display: flex;
                align-items: center;

                &__img-box {
                    padding: 40px 60px;
                    img {
                        max-width: 200px;
                        width: 100%;
                    }
                }
                .title {
                    @include font-size(32, 40, 700);
                    margin-bottom: 4px;
                }
                
                .description {
                    margin-bottom: 40px;
                }

                &__text {
                    max-width: 634px;
                    padding-top: 40px;
                    .code {
                        color: $dark-gray;
                        margin-bottom: 24px;
                    }

                    .--text-s {
                        font-weight: 400;
                        letter-spacing: 0.02em;

                        p {
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 16px;
                @include device-md {
                    flex-direction: column;
                }
            }
        }
    }

    @include device-lg {
        .modal__box__content {
            .product {
                flex-direction: column;
                align-items: flex-start;
                &__img-box {
                    align-self: center;
                }
                &__text {
                    padding-top: 0;
                }
                .title {
                    @include font-size(24, 32, 700)
                }
            }
            .btn {
                width: 100%;
            }
        }
    }
}
