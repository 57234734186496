.equipments-filter {
    &__search-bar{
        align-items: center;
        display: flex;
        justify-content: center;
        padding-block: 20px;
        width: 100%;
        gap: 16px;

        &-box{
            background-color: $light-gray-25;
            margin-bottom: 24px;
        }

        &__input {
            border-radius: 6px;
            border: 1px solid $medium-gray;
            background: transparent;
            padding: 8px 18px;
            color: $dark-gray;
            width: 100%;
            height: 100%;
            @include font-size(18, 24, 400);
            @include device-md {
                @include font-size(14, 19, 400);
            }

            &-box{
                display: flex;
                align-items: center;
                gap: 16px;
                height: 40px;
                flex-basis: 100%;
            }

            &:focus {
                border-color: $green;
                outline: none;
                color: $black;
            }
        }
    }
    &__selects-box {
        row-gap: 20px;
        @include main-grid;
        @include main-grid-columns(
            (
                'xl': 2,
                'lg': 2,
                'md': 2,
                'sm': 1,
            )
        );
        .--select{
            width: 100% !important;
        }
    }
}
