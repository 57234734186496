.feedback-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100svh;
    justify-content: space-between;
    .main-body__content {
        min-height: initial;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .header__placeholder {
        @include rwd-min(1360) {
            display: none !important;
        }
    }

    .h4 {
        max-width: 550px;
    }

    @include device-md {
        .h4 {
            font-size: 24px;
        }
    }

    &-container {
        max-width: 700px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        @include device-lg {
            gap: 12px;
        }
        * {
            text-align: center;
        }
        .--icon-circle {
            margin-bottom: 12px;
            min-width: 72px;
            min-height: 72px;
            max-width: 72px;
            max-height: 72px;
            padding: 8px; 
            @include device-lg {
                min-width: 40px;
                min-height: 40px;
                max-width: 40px;
                max-height: 40px;
                padding: 4px; 
            }
        }
        &__cta {
            margin-top: 28px !important;
        }
    }
}
