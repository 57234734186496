.btn {
    user-select: none;
    border: 1px solid transparent;
    background: none;
    outline: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    display: block;
    @include font-size(14, 19, 700);
    cursor: pointer;
    @include flex-center;
    gap: 10px;
    min-width: 140px;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    font-weight: 700;
    line-height: 1;
    transition:
        color $timming ease-in-out,
        background-color $timming ease-in-out,
        border-color $timming ease-in-out;
    text-align: center;

    @include device-md {
        min-width: auto !important;
    }

    // Sizes
    &.--small {
        min-height: 36px;
        padding-left: 16px;
        padding-right: 16px;
        @include font-size(14, 19, 700);
    }

    &.--medium {
        min-height: 40px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 6px;
        @include font-size(14, 19, 700);
    }

    &.--big {
        min-height: 48px;
        min-width: 250px;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 8px;
        @include font-size(16, 22, 700);
    }

    &.--freezed {
        pointer-events: none !important;
    }

    // Variants
    &-primary {
        background-color: $green;
        color: $white;

        &:hover {
            background-color: transparent;
            border: 1px solid $green;
            color: $green;
        }
    }

    &-secondary {
        background-color: $white;
        color: $green;

        &:hover {
            background-color: transparent;
            color: $white;
            border: 1px solid $white;
        }

        &:active {
            background: rgba(255, 255, 255, 0.10);
            color: $medium-gray;
            border: 1px solid $medium-gray;
        }
    }

    &-tertiary {
        border: 1px solid $light-green-100;
        color: $green;
        background-color: $light-green-50;

        &:hover {
            background-color: $green;
            color: $white;
            border-color: $green;
        }
    }

    &-outlined {
        border: 1px solid $green;
        background-color: $white;
        color: $green;

        &:hover {
            background-color: $success-50;
        }

        &:active {
            background-color: $success-50;
            color: rgba(0, 134, 87, 0.70);
            border: 1px solid rgba(0, 134, 87, 0.70);
        }

        &--white {
            border: 1px solid $white;
            background-color: transparent;
            color: $white;

            &:hover {
                border-color: $green;
                background-color: $white;
                color: $green;
            }

            &:active {
                color: $success;
                background-color: $white;
                border-color: $light-gray;
            }
        }
    }

    &-orange {
        border: 1px solid $orange;
        background-color: $orange;
        color: $white;

        &:hover {
            background: rgba(250, 250, 250, 1);
            color: $orange;
            border-color: $orange;
        }
    }

    &-whatsapp {
        border: 1px solid $green;
        background-color: $green;
        color: $white;

        & > svg {
            width: 20px !important;
            height: 20px !important;
        }

        &:hover {
            background-color: $white;
            color: $green;
            border-color: $green;
            svg path {
                fill: $green;
            }
        }
    }

    &-delete {
        border: 1px solid $danger;
        background-color: $white;
        color: $danger;

        &:hover {
            background-color: #fffbfa;
        }
    }

    &.--disabled, &:disabled {
        pointer-events: none;
        cursor: not-allowed;
        background-color: $medium-gray;
        color: $dark-gray;
    }

    svg {
        width: 1em;
        height: 1em;
        color: currentColor;
    }

    &.--no-min-width {
        min-width: auto;
    }

    .btn-loading-icon {
        display: none;
    }

    &.--loading {
        border-color: $light-green-50 !important;
        background: $light-green-50 !important;
        pointer-events: none !important;

        .btn-label {
            display: none;
        }

        .btn-loading-icon {
            display: inline-block;
            margin: auto !important;
            height: 20px !important;
            width: 20px !important;
            animation: infinite-spinning 2.1s linear infinite;
            stroke: $green;
        }
    } // egoForm

    &.back-button {
        display: flex;
        align-items: center;
        gap: .5rem;

        margin: 0;
        padding: 0;
        font-weight: 400;
        transition: color .2 ease-in-out;

        svg {
            width: unset;
            height: unset;
            path {
                transition: stroke .2 ease-in-out;
            }
        }

        &:hover {
            color: $green;
            svg path {
                stroke: $green;
            }
        }
    }
}
@keyframes infinite-spinning {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.text-link:not(.btn) {
    display: inline-flex;
    gap: 8px;
    padding-block: 8px;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    color: $green;
    @include flex-center;
    @include font-size(14, 14, 700);

    &.--freezed {
        pointer-events: none !important;
    }

    svg {
        width: 1.2em;
        height: 1.2em;
    }

    &:hover {
        border-radius: 6px;
        background: rgba(0, 134, 87, 0.1);
        padding-inline: 8px;
    }

    &.--small {
        @include font-size(12, 12, 700);
    }
}

.btn-filter {
    cursor: pointer;
    border: 2px $light-gray solid;
    background-color: $light-gray;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease-in-out;

    svg {
        display: block;
        stroke: $dark-gray;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        border: 2px $green solid;
        background-color: $white;

        svg {
            stroke: $green;
        }
    }

    &.--selected {
        border: 2px $green solid;
        background-color: $white;

        svg {
            stroke: $green;
        }
    }

    .tooltip {
        position: fixed;
        font-size: 12px;
        line-height: 20px;
        background: $green;
        border-radius: 6px;
        visibility: hidden;
        padding: 6px;
        opacity: 0;
        transition:
            opacity 0.2s,
            visiblity 0s;
        color: $white;
        z-index: 1;
        font-weight: 700;
    }
    &:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }
}

.form-group {
    position: relative;

    input {
        outline: transparent;
        display: block;
        position: relative;
        z-index: 0;
        border: 1px solid $medium-gray;
        background-color: $white;
        border-radius: 8px;
        padding: 12px;
        height: 46px;
        width: 100%;
        font-family: "Nunito", sans-serif;
        @include font-size(16, 22, 400);
        transition: all $timming ease-in-out;
        background-repeat: no-repeat;
        background-position: center right 16px;
        color: $black;

        &:hover:not(:disabled) {
            border-color: $green;
        }

        &:focus {
            border-color: $green;
            outline: none;
            color: $black;
        }
    }

    input:focus + label,
    input:valid + label {
        @extend .active;
    }

    select {
        outline: transparent;
        display: block;
        position: relative;
        z-index: 0;
        border: 1px solid $medium-gray;
        background-color: $white;
        border-radius: 8px;
        padding: 12px 32px 12px 12px;
        height: 46px;
        width: 100%;
        font-family: "Nunito", sans-serif;
        @include font-size(16, 22, 400);
        transition: all $timming ease-in-out;
        background-repeat: no-repeat;
        background-position: center right 16px;
        color: $black;
        -webkit-appearance: none; /* Quitar la apariencia nativa del select */
        appearance: none;

        &:focus {
            border-color: $green; /* Cambiar color del borde al enfocar */
        }
    }

    label {
        position: absolute;
        top: 11px; /* Ajustar la posición del label */
        left: 12px;
        pointer-events: none;
        transition: 0.2s ease-in-out;
        color: $green;

        &.active {
            top: -0.7rem; /* Mover el label por encima del input */
            font-size: 0.75rem;
            color: $green;
            background-color: $white; /* Agregar un fondo blanco al label */
            padding: 0 0.25rem; /* Añadir espacio alrededor del texto del label */
            &:after {
                content: " *";
            }
        }
    }

    select:focus + label,
    select:valid + label {
        @extend .active;
    }

    &.--select {
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0.75rem; /* Ajustar la posición de la flecha */
            transform: translateY(-50%);
            pointer-events: none;
            width: 21px;
            height: 20px;
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M5 7.5L10 12.5L15 7.5" stroke="%23008657" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>')
                no-repeat;
            background-size: contain;
        }
    }
}
