.homepage__events {
    // padding-top: 90px;
    // padding-bottom: 90px;
    background-color: $light-gray;

    .--text-m {
        letter-spacing: -0.02em;
        margin-bottom: 21px;
    }

    &__cards {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        &__title {    
            display: flex;
            justify-content: space-between;
            // padding-left: 8px;
            margin-bottom: 24.5px;

            .text-link {
                text-decoration: none;
                padding-right: 8px;
            }

            .--text-m {
                margin-bottom: 0;
            }

        }
        
        &__events {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 30px;
            row-gap: 24px;
            justify-items: center;
        }

        .btn {
            width: 222px;
            margin-top: 24px;
            align-self: flex-end;
        }
    }

    @include rwd(1150) {
        &__cards {
            div {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @include rwd(800) {
        &__cards {
            div {
                grid-template-columns: 1fr;
                justify-items: center
            }
        }
    }
    

    @include device-lg {
        .--text-body-xl {
            @include font-size(20, 27.28, 700, -2);
        }

        &__cards {
            .btn {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @include device-sm {
        &__cards {
            .btn {
                width: 100%;
            }
        }
    }
    
}