.homepage__big-img-with-card {
    display: grid;
    grid-template-columns:
        1fr repeat(11, minmax(0, calc(($min_width_wrapper - ($gg-lg * 11)) / 12)) $gg-lg)
        minmax(0, calc(($min_width_wrapper - ($gg-lg * 11)) / 12))
        1fr;
    align-items: center;

    @include device-md {
        grid-template-columns: 1fr;
    }

    &__card {
        padding: 60px;
        background-color: $white;
        border: 1px solid $light-gray;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        height: max-content;
        z-index: 1;

        grid-column: 14/25;
        grid-row: 1;
        @include device-md {
            padding: 30px;
            grid-row: 2;
            grid-column: 1/-1;
            margin-inline: 16px;
            margin-top: -40px;
        }

        .h2 {
            margin-bottom: 24px;
            max-width: 405px;
            @include font-size(32, 40, 700, -2);
        }

        h5 {
            @include font-size(20, 24, 500);
            margin-bottom: 8px;
            max-width: 405px;
        }

        p {
            margin-bottom: 5px;
            max-width: 405px;
        }

        a {
            margin-top: 16px;
            color: $green;
            text-decoration: none;
            display: flex;
            align-items: center;
            max-width: 405px;
            @include font-size(16, 20.8, 700);
            

            > svg {
                margin-left: 5px;
            }
        }
    }

    &__image {
        display: block;
        grid-column: 1/23;
        grid-row: 1;
        position: relative;
        > img {
            border-radius: 0px 24px 24px 0px;
            height: 590px;
            object-fit: cover;
            max-width: 100%;
            @include device-md {
                height: 300px;
            }
        }

    }
}
