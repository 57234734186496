.bullets-image-fifty-fifty {
    &.left {
        .bullets-image-fifty-fifty__container {
            flex-direction: row;
            img {
                margin-right:56px
            }
        }
    }

    &.right {
        .bullets-image-fifty-fifty__container {
            flex-direction: row-reverse;
            img {
                margin-left:56px
            }
        }
    }

    .h4 {
        color: $green;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 80px;

        img {
            max-width: 541.84px;
            max-height: 425.64px;
            object-fit: contain;
            width: 50%;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            margin-bottom: 39px;

            .green-circle {
                margin-right: 24px;
                min-width: 40px;
                max-width: 40px;
                min-height: 40px;
                max-height: 40px;
                background-color: $green;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 3px;

                p {
                    color: $white;
                    text-align: center;
                    @include font-size(20, 27.28, 700, 0)
                }
            }
            
            p {
                letter-spacing: -0.02em;
            }

            .--text-body-l {
                margin-bottom: 8px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    @include device-lg {
        &.left, &.right {
            .bullets-image-fifty-fifty__container {
            flex-direction: column;
            margin-top: 32px;
            align-items: flex-start;

                img {
                    width: 100%;
                    margin-bottom: 40px;
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }
}