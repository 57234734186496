.hero-home {
    color: $white;
    position: relative;
    overflow: hidden;

    &__background-desktop,
    &__background-mobile {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }

    &__background-mobile {
        display: none;
    }

    &__img-mob {
        display: none;
    }

    &__swiper {
        .swiper-wrapper {
            .swiper-slide {
                height: auto;

                .hero-home__content {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    height: 100%;
                    column-gap: 30px;
                    @include rwd-min(600){
                        align-items: center;
                    }
                }
            }
        }
    }

    &__nav-pag {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        top: -62px;
        left: 50%;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.1);
        transform: translate(-50%);
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 19px;
        width: 109px;
        height: 36px;

        .swiper-button-next::after,
        .swiper-button-prev::after {
            font-size: 18px;
            color: $black;
            margin: 0;
            @include font-size(14, 19, 700, 0);
            color: $white;
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: initial !important;
            margin-top: 1px;
        }

        .swiper-pagination {
            position: initial !important;
            margin: 0;
            @include font-size(14, 19, 700, 0);
            color: $white;
        }

        .swiper-button-lock {
            display: flex;
        }
    }

    &__text {
        max-width: 540px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-block: 120px;
        position: relative;

        .h2 {
            font-weight: 400 !important;
        }

        .--text-body-m {
            margin-bottom: 48px;
            max-width: 450px;
            margin-top: 16px;
        }

        .desktop {
            .btn {
                width: 255px;
            }
        }
    }

    .hero-home-desktop__image {
        width: 100%;
        max-width: 540px;
        position: relative;
    }

    .hero-home-mobile__image {
        display: none;
        position: relative;
    }

    @include device-lg {
        &__text {
            .h2 {
                @include font-size(34, 38, 400, 0);
                margin-bottom: 10px;
                margin-top: 34px;
            }

            .--text-l {
                @include font-size(16, 20, 400, 0);
                margin-bottom: 26px;
            }
        }
    }

    @include rwd-min(600) {
        &__text {
            &.wrapper {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    @include rwd(600) {
        &__content {
            &.wrapper {
                padding-left: 0;
                padding-right: 0;
            }
            &.--solid-colors {
                .hero-home {
                    &__text {
                        background-color: $black;
                    }
                    &__image-box {
                        background-color: $green;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        min-height: 274px;
                    }
                }
            }
        }
       
        &__background-desktop {
            display: none;
        }

        &__background-mobile {
            display: block;
        }

        &__swiper {
            .swiper-wrapper {
                .swiper-slide {
                    .hero-home__content {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }

        &__img {
            display: none;
        }

        &__img-mob {
            display: block;
        }

        &__nav-pag {
            margin-top: -46px;
            margin-bottom: 10px;
            top: 0;
            background-color: rgba(17, 17, 17, 0.5);
        }

        .hero-home-desktop__image {
            display: none;
        }

        .hero-home-mobile__image {
            width: 100%;
            max-width: 310px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        &__text {
            padding-block: 28px;
            width: 100%;
            .desktop {
                .btn {
                    width: 100%;
                }
            }
        }
    }
}
