.slider-events {
    &__item {
        max-width: 100%;
        max-height: 398px;
        aspect-ratio:1110/384;

        img {
            width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: contain;

            &.desktop {
                display: block;
            }

            &.mobile {
                display: none;
            }

            @include rwd(500) {
                &.desktop {
                    display: none;
                }
    
                &.mobile {
                    display: block;
                    // max-width: 345px;
                    margin-left: auto;
                    margin-right: auto;
                } 
            }
        }

        @include rwd(500) {
            max-width: 100%;
            max-height: 520px;
            aspect-ratio: 520/1272;
        }
    }

    &__swiper {
        padding-bottom: 40px !important;
        
        .swiper-pagination-bullet-active {
            background-color: $green;
        }
        
        .swiper-pagination-horizontal {
            bottom: 0px !important;
        }
    }
}