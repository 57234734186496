.cards-recommender-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;

    gap: 1rem;

    @include device-md("min") {
        flex-direction: row;
    }
}

.card-recommender {
    border-radius: 10px;
    border: 1px solid $medium-gray;
    transition: 0.3s;
    overflow: hidden;
    background-color: $white;

    &:hover {
        border-color: $green;
    }

    .paragraph {
        color: $dark-gray;
    }

    .checkbox-container {
        width: 100%;
        padding: 1rem 0;
    }
}

.card-recommender-stage {
    display: flex;
    flex-direction: column;
    width: 100%;

    img {
        display: none;

        @include device-md("min") {
            display: block;
            width: 100%;
            height: 350px;
            object-fit: cover;
        }
    }

    .body {
        flex-grow: 1;
        padding: 24px 16px;
        border-bottom: 1px solid $medium-gray;
    }

    .buttons {
        display: flex;

        input,
        label {
            cursor: pointer;
        }
    }
}

.card-recommender-family {
    display: flex;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;

    &:has(.checkbox-container input:checked) {
        border-color: $green;
    }

    img {
        width: 100%;
        max-width: 160px;
        aspect-ratio: 160/105;
        object-fit: contain;
        padding: 10px;

        @include device-md {
            display: none;
        }
    }

    .body {
        padding: 16px 24px;
        width: 100%;
    }

    .buttons {
        display: flex;

        justify-content: center;
        align-items: center;

        padding: 24px;
    }
}

.card-recommender-subfamily {
    display: grid;
    align-items: center;
    grid-template-columns: auto 30px;
    gap: 1rem;
    width: 100%;
    border-radius: 16px;
    border: 1px solid $medium-gray;
    cursor: pointer;
    background-color: $white;
    padding: 18px 19px;

    &:has(.checkbox-container input:checked) {
        border-color: $green;
    }

    &.--hidden {
        display: none;
    }

    p {
        @include font-size(18, 22, 600);
    }

    .checkbox-container {
        padding: 0;
    }
}
