.title-text-landings {
    &__title {
        @include font-size(24, 30, 700);
        margin-bottom: 22px;
        color: $green;
    }

    &__subtitle {
        @include font-size(18, 28, 400);
        color: #000000;
    }

    @include device-md {
        &__title {
            @include font-size(18, 28, 700);
            margin-bottom: 15px;
        }
    
        &__subtitle {
            @include font-size(14, 20, 400);
            color: $black;
        }
    }
}