.products-filtered {
    &-container {
        display: flex;
        flex-direction: column;
    }
    .products-filtered-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        position: relative;
        th {
            color: $dark-gray;
            @include font-size(14, 17.5, 400);
            text-align: start;
            width: 125px;
            padding: 1px 8px 0;
            height: 48px;
            vertical-align: middle;
            text-wrap: balance !important;

            @include device-lg {
                width: 20%;
                height: auto;
                padding: 8px;
            }
        }
        td {
            @include font-size(18, 24.5, 400);

            text-align: start;
            padding: 8px 8px;
            height: 48px;
            vertical-align: middle;

            @include device-lg {
                text-align: center;
                @include font-size(14, 19, 400);
            }
        }
        @include device-lg {
            tr td:nth-child(n + 7) {
                display: none;
            }
            tr th:nth-child(n + 7) {
                display: none;
            }
            th.only-mobile {
                display: table-cell !important;
            }
            tr.only-mobile {
                display: table-row !important;
            }
            tr td:last-child {
                display: table-cell !important;
            }
        }
        @include device-md {
            tr td:nth-child(n + 6) {
                display: none;
            }
            tr th:nth-child(n + 6) {
                display: none;
            }
        }
        @include device-sm {
            tr td:nth-child(n + 5) {
                display: none;
            }
            tr th:nth-child(n + 5) {
                display: none;
            }
        }

        &__data {
            tr:nth-child(2n) {
                background-color: $light-gray; /* Aplicar fondo gris a las filas pares a partir de la tercera fila */
            }

            tr td:first-child {
                text-align: start; /* O text-align: left; */
            }

            tr td:last-child {
                text-align: right;
            }

            tr:hover {
                cursor: pointer;

                .products-filtered-table__data__chevron {
                    stroke: $green;
                }
                td {
                    background-color: $light-green-50;
                }
            }

            &__chevron {
                stroke: $dark-gray;
                vertical-align: middle;
            }

            &__product-name {
                font-weight: 700 !important;
                color: $black;
                span {
                    @include font-size(12, 16, 400);
                }
            }
        }

        &__headings {
            @include device-lg {
                box-shadow:
                    0px 2px 4px -2px rgba(16, 24, 40, 0.03),
                    0px 4px 8px -2px rgba(16, 24, 40, 0.05);
                background-color: $white;

                th {
                    border-right: 1px solid $medium-gray;
                }
            }
            @include device-lg('min') {
                th {
                    border-bottom: 1px solid $medium-gray;
                }
            }

            .vertical-header {
                @include device-lg {
                    transform: rotate(-180deg);
                    writing-mode: vertical-rl; /* Rotar el texto verticalmente */
                    text-align: start;
                    white-space: nowrap; /* Evita que el texto se ajuste automáticamente al ancho de la celda */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 90px;
                }
            }

            .equipment-th {
                width: 15vw;
                @include device-lg {
                    width: 25vw;
                }
                @include device-sm {
                    width: 30vw;
                }
                div {
                    width: 15vw;
                    text-align: start;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;

                    @include device-lg {
                        width: 25vw;
                    }
                    @include device-sm {
                        width: 30vw;
                    }
                    @include device-lg {
                        // width: 30%;
                        border-right: 0 !important;
                    }
                }
            }

            .th-placeholder {
                width: 5%;
                text-align: right;
                padding: 0;
                border-right: 0;
                border-left: 1px solid $medium-gray;
            }

            .products-filtered-table__line-btn {
                &.only-mobile {
                    height: 36px;
                    th {
                        margin: auto;
                        text-align: center;
                        border-top: 1px solid $medium-gray;
                        border-right: 0;
                    }
                }

                &.only-desk {
                    text-align: right;
                    .text-link {
                        text-decoration: none;
                        background: rgba(0, 134, 87, 0.1);
                        border-radius: 6px;
                    }
                    &.products-filtered-table__line-btn {
                        width: 125px;
                    }
                }
            }
        }

        .only-mobile {
            @include device-lg('min') {
                display: none;
            }
        }

        .only-desk {
            @include device-lg {
                display: none;
            }
        }
    }
    .hide {
        display: none;
    }

    *[class^='hide-'] {
        display: none;
    }
}
