.search-bar {
    background-color: $green;

    #searchBarForm {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
      
        > svg {
            color: $white;
            margin-right: 16px;
        }

        .btn {
            height: 40px;
            margin-left: 30px;
            @include device-md {
                margin-left: 8px;
            }
    
        }

        .search-bar__input {
            width: 100%;
            display: block;
            position: relative;
            z-index: 0;
            border: 1px solid $medium-gray;
            background-color: $white;
            border-radius: 8px;
            padding: 9px 17px;
            height: 40px;
            font-family: "Nunito", sans-serif;
            @include font-size(18, 24.6, 400);
            transition: all $timming ease-in-out;
            background-repeat: no-repeat;
            background-position: center right 16px;
            &::placeholder {
                color: #6D6D6D;
            }

            &:hover {
                border-color: $green;
            }

            &:focus {
                border-color: $green;
                outline: none;
                color: $black;

                &::placeholder {
                    color: $black;
                }
            }
        }

        @include device-md {
            position: relative;
            // padding-left: 16px;
            // padding-right: 16px;

            > svg {
                position: absolute;
                z-index: 1;
                color: $dark-gray;
                left: 10px;
            }

            .search-bar__input {
                padding-left: 45px;
                @include font-size(14)
            }
        }
    }
}
