.quicked_link_cards {
    background-color: $green;
    padding-top: 64px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;

    @include device-md {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .swiper {
        overflow: unset;
    }
    .swiper-slide {
        display: flex;
        align-items: stretch;
        height: auto;
    }
    &__header {
        max-width: 540px;
        margin-inline: auto;
        text-align: center;
        color: $white;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        padding-bottom: 40px;
        .h4 {
            font-weight: 400;
            strong {
                font-weight: 700 !important;
            }
        }
        p {
            @include font-size(16)
        }
        @include device-md {
            padding-bottom: 24px;
        }
    }
    &__link {
        display: block;
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            color: unset;
        }
    }
    &__article {
        $self: &;
        flex: 1;
        height: auto;
        background-color: $white;
        border-radius: 16px;
        padding: 24px;
        @include device-md {
            padding: 16px;
        }
        &:hover {
            #{$self}__title {
                color: $green;
            }
        }
        &__image {
            aspect-ratio: 1.3;
            width: 100%;
            object-fit: cover;
            border-radius: 8px;

            @include device-md {
                aspect-ratio: 1.8;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 24px;
        }
        &__title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all $timming ease-in-out;

            @include font-size(24, 33, 700, -2);
            @include device-md {
                @include font-size(20, 24, 700);
            }
        }
        &__text {
            @include font-size(16, 24);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
            text-overflow: ellipsis;
            @include device-md {
                -webkit-line-clamp: 3;
            }
        }
    }
    &__stripes {
        position: absolute;
        bottom: 0;
        left: 0;
        @include rwd-min(1900){
            width: 50%;
        }
        @include device-md {
            bottom: -30%;
        }
    }
}
