.card-spare-parts {
    .h4 {
        margin-bottom: 2.5rem;
    }

    &__container {
        display: flex;

        flex-direction: column;

        gap: 2rem;

        @include device-md("min") {
            flex-direction: row;
        }
    }

    &__content {
        height: 100%;
        max-width: 100%;
        background-color: $white;
        padding: 24px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        border: 1px solid $medium-gray;
        transition: border-color $timming ease-in-out;
        text-decoration: none;

        .--text-s {
            font-weight: 400;
            letter-spacing: -0.02em;
        }

        .h4 {
            color: $green;
            margin-bottom: 16px;
        }

        img {
            width: 100%;
            height: 219.5px;
            object-fit: contain;
            margin-bottom: 16px;
        }


        &:hover {
            border-color: $green;
        }
    }

    &__cta {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-end;
        align-items: flex-end;
        flex-grow: 1;
        color: $green;
        text-decoration: none;
        @include font-size(14, 14, 700);
    }

    .swiper {
        // padding-bottom: 60px;

        .swiper-slide {
            margin-right: 0;
        }

        .swiper-pagination-bullet-active {
            background-color: $green;
        }

        .swiper-pagination {
            position: static;
        }
    }
}

.swiper-card-spare-parts {
    &__link {
        height: 100%;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            all: unset;
            cursor: pointer;
        }
    }

    .swiper-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        height: 24px;
        margin-top: 1rem;
    }

    .swiper-pagination {
        max-width: max-content;
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: relative;
        height: 20px;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-size: 20px;
        color: $green;
    }
}