.dropdown-images {
    .h4 {
        margin-bottom: 57px;
        font-weight: 400;
    }
    
    &__title {
        @include font-size(20, 25, 600);
        margin-bottom: 24px;
        transition: all $timming ease-in-out;
    }

    .accordion-dropdown-images:hover {
        cursor: pointer;
    }

    .accordion-dropdown-images {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 21.5px;
        border-bottom: 1px solid $medium-gray;

        .--icon-circle {
            max-height: 28px;
            max-width: 28px;
            min-height: 28px;
            min-width: 28px;

            &.toggle-down {
                border: 1px solid #d7e7e1;
                background-color: $white;
                color: $dark-gray;
            }

            &.toggle-up {
                background-color: $green;
                border: 1px solid $green;
                color: $white;
            }
        }

        .toggle-up {
            display: none;
        }

        &.active {
            border-color: $green;

            .dropdown-images__title {
                color: $green;
            }
        }
    }

    .panel {
        display: none;
        flex-direction: column;
        overflow: hidden;
        padding-left: 40px;

        li {
            div {
                overflow: auto;
                
                img {
                    width: 100%;
                    min-width: 1000px;
                }
            }
        }


        .dropdown-images__title {
            color: $green;
            border-bottom: 1px solid $green;
            padding-bottom: 16px;
            margin-bottom: 24px;
            margin-top: 40px;
        }
    }
}
