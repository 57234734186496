.text-img-fourty-sixty {
    .wrapper {
        display: flex;
        justify-content: center;
    }

    &__text {
        max-width: 376px;
        margin-top: 141px;
        margin-left: 16px;
        width: 40%;
        
        .h4 {
            margin-bottom: 24px;
            color: $green;
        }
    }

    &__image {
        max-width: 527px;
        height: 433px;
        object-fit: contain;
        border-radius: 10px;
        width: 60%;
    }

    @include device-lg {
        .wrapper {
            flex-direction: column;
            align-items: center;

            .text-img-fourty-sixty__image {
                margin-left: 0;
                margin-right: 0;
            }
        }

        &__text {
            max-width: 100%;
            margin-top: 24px;
            margin-left: 0;
            width: 100%;
        }

        &__image {
            width: 320px;
            height: 168px;
            object-fit: contain;
            width: 100%;
        }
    }
    
    @include device-sm {
        &__image {
            width: 100%;
            height: 168px;
            object-fit: contain;
        }
    }
}