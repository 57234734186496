.tooltip {
    position:relative;

    // Box
    &:before {
        content: attr(data-text);
        position:absolute;
        inset: 50% auto auto 100%;
        transform:translateY(-50%);
        margin-left: 9px;
        width:200px;
        padding:10px;
        border-radius:8px;
        background: $black;
        color: #fff;
        text-align:center;
        pointer-events: none;
        opacity:0;
        transition:.3s opacity;
    }

    &.--left:before {
        margin:initial;
        inset: 50% 100% auto auto;
        margin-right:9px;
    }

    &.--bottom:before {
        margin: initial;
        inset: 100% auto auto auto;
        margin-top: 4px;
        transform: translateX(-50%);
    }

    &.--top:before {
        margin: initial;
        inset: auto auto 100% auto;
        margin-bottom: 9px;
        transform: translateX(-50%);
    }

    &:hover:before {
        opacity: 1;
    }

    // Arrow
    &:after {
        content: "";
        position:absolute;
        left:100%;
        top:50%;
        margin: 0 0 0 -5px;
        transform:translateY(-50%);

        border: 7px solid $black;
        border-color: transparent $black transparent transparent;
        pointer-events: none;
        opacity:0;
        transition:.3s opacity;
    }

    &.--left:after {
        left: initial;
        right: 100%;
        border-color: transparent transparent transparent $black;
        margin: 0 -5px 0 0;
    }

    &.--bottom:after {
        inset: 50% auto auto 50%;
        transform: translateX(-50%);
        border: 7px solid $black;
        border-color: transparent transparent $black;
        margin: 0 5px 5px 0;
    }

    &.--top:after {
        inset: auto auto 50% 50%;
        transform: translateX(-50%);
        border: 7px solid $black;
        border-color: $black transparent transparent transparent;
        margin: 5px 0px 5px 0;
    }
}

.tooltip:hover:before, .tooltip:hover:after {
    opacity: 1;
}
