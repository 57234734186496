.paginator {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 15px 0;

    @include device-sm('max') {
        justify-content: space-between;
    }

    .btn-arr {
        svg {
            transform: scale(1.2);
        }

        &.prev {
            svg {
                transform: scale(1.2) rotate(180deg);
            }
        }

        &.disabled {
            pointer-events: none;
            opacity: .5;
        }
    }

    &__pages {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        @include device-sm('max') {
            display: none;
        }

        .page-btn {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border-radius: 50%;
            width: 32px;
            height: 32px;

            &.active {
                background-color: $green;
                color: $white;
            }
        }
    }
}