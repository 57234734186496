.cards-features {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: flex-start;

    &__mobile {
        display: none;
    }

    @include rwd(1240){
        display: none;

        &__mobile {
            display: block;

            .swiper {
                padding-bottom: 60px;

                .swiper-slide {
                    margin-right: 0;

                    .card-features {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .swiper-pagination-bullet-active {
                    background-color: $green;
                }
            }
        }
    }
}