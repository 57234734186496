#branches {
    // HEADER
    // position: relative;
    & > .wrapper {
        position: unset;
    }
    .header-heading {
        margin-bottom: 40px;
        font-weight: 400;
        @include device-md {
            font-size: 24px;
            font-style: normal;
            letter-spacing: -0.48px;
        }
    }
    .header-wrapper {
        hr {
            border: 0;
            border-bottom: 1px solid $medium-gray;
            padding-top: 20px;
            margin-bottom: 0;
        }

        .hr-wrapper {
            @include device-md {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    article.header-grid {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto;
        grid-template-areas: "checks selects views";
        align-items: center;
        grid-column-gap: 20px;

        aside.header-checks {
            grid-area: checks;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            column-gap: 16px;
            row-gap: 8px;
            .form__field {
                margin-bottom: 0;
            }
        }
        aside.header-selects {
            grid-area: selects;
            display: flex;
            justify-content: flex-end;
            align-content: center;
            text-align: right;

            .select-group {
                display: inline-flex;
                gap: 12px;
            }
        }
        aside.header-views {
            grid-area: views;
            align-content: center;
            text-align: right;

            button.branches-view {
                background-color: transparent;
                border: 0;
                outline: none;
                padding: 3px;
                line-height: 1;

                svg {
                    transition: opacity 0.3s ease;
                    opacity: 0.4;
                }
                &.active {
                    svg {
                        opacity: 1;
                    }
                }
                .tooltip {
                    position: fixed;
                    font-size: 12px;
                    line-height: 20px;
                    background: $green;
                    border-radius: 6px;
                    visibility: hidden;
                    padding: 6px;
                    opacity: 0;
                    transition: opacity 0.2s, visiblity 0s;
                    color: $white;
                    z-index: 1;
                    font-weight: 700;
                }
                &:hover .tooltip {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        @include rwd(1250) {
            grid-template-columns: auto auto 100px;
            grid-row-gap: 30px;
            grid-template-areas:
                "checks checks views"
                "selects selects selects";

            aside.header-selects {
                justify-content: flex-start;
            }
        }
        @include device-md {
            aside.header-selects {
                .select-group {
                    width: 100%;

                    .form__field.--select {
                        max-width: none;
                        min-width: auto;
                        width: 100%;
                    }
                }
            }
        }

        @include rwd(650) {
            grid-template-columns: auto auto 100px;
            grid-row-gap: 30px;
            grid-template-areas:
                "views views views"
                "checks checks checks"
                "selects selects selects";
        }
        @include rwd(500) {
            .select-group {
                display: flex;
                gap: 15px;
                flex-direction: column;
                margin-top: 5px;
            }
        }
        @include rwd(400) {
            grid-row-gap: 16px;
        }
    }

    // LIST
    .branch-list-map {
        .branch-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: auto;
            column-gap: 30px;
            row-gap: 40px;
            list-style: none;
            margin: 0;
            padding: 0;
            align-items: stretch;
            @include device-lg {
                grid-template-columns: 1fr 1fr 1fr;
            }
            @include device-md {
                grid-template-columns: 1fr 1fr;
            }
            @include device-sm {
                grid-template-columns: 1fr;
            }
            span {
                display: block;
            }
            &__box-title {
                color: $dark-gray;
                font-size: 32px;
                font-weight: 300;
                line-height: 40px;
                letter-spacing: -0.64px;
                padding-bottom: 40px;
            }
        }
        .hidden-location,
        .hidden-category {
            display: none;
        }
    }
    .branch,
    .branches-map-info {
        &__title {
            color: $green;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32.7px;
            @include device-md("min"){
                max-width: 90%;
            }
        }
        &__categories {
            font-weight: 500;
            margin-top: 8px;
            list-style-type: none;
            margin-bottom: 20px;
        }
        &__category {
            display: flex;
            align-items: center;
            gap: 8px;
            color: $green;
            font-size: 16px;
            font-weight: 700;
        }
        &__info-list {
            font-size: 16px;
            font-weight: 300;
        }
        &__subtitle {
            color: $black;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: -0.32px;
            margin-bottom: 8px;
        }
        &__email {
            a {
                color: $green;
            }
        }
    }


    // MAP
    .view.map {
        position: relative;
        margin-bottom: 60px;
        &.wrapper {
            @include device-md {
                padding-left: 0;
                padding-right: 0;
            }
        }
        &.hidden-map {
            position: absolute;
            left: -100%;
        } // Necesario para actualizar el mapa cuando no está visible

        .branches-map-info-wrapper {
            position: absolute;
            z-index: 10;
            top: 15px;
            right: 70px;
            bottom: 15px;
            width: 300px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            pointer-events: none;

            @include device-md {
                padding-left: 16px;
                padding-right: 16px;
            }

            .branches-map-info {
                display: none;
                position: relative;
                padding: 40px 20px;
                pointer-events: all;
                background-color: $white;
                box-shadow: 0 10.9px 19.1px rgba(0, 0, 0, 0.042),
                    0 24.2px 34.4px rgba(0, 0, 0, 0.064),
                    0 48px 80px rgba(0, 0, 0, 0.1);

                button.close {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    padding: 5px;
                    background: none;
                    border: 0;
                    color: $medium-gray;
                }
            }
        }

        #branches-map {
            background-color: $medium-gray;
            min-height: 540px;
        }
    }

    @media (max-width: 800px) {
        .view.map {
            .branches-map-info-wrapper {
                position: static;
                display: block;
                width: auto;

                .branches-map-info {
                    box-shadow: none;
                    padding-left: 0;
                    padding-right: 0;

                    button.close {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: 400px) {
        h3 {
            margin-top: 25px;
            margin-bottom: 15px;
        }

        .view.map {
            #branches-map {
                min-height: 350px;
            }
        }
    }

    #branch-list-arg {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    #branch-list-others {
        background-color: $light-green-50;
        outline: 1.5px solid $light-green-50;
        z-index: 1;
        position: relative;
        svg rect {
            fill: white
        }
        .wrapper {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
    .branch-list-empty {
        display: none;
        margin-top: 60px;
        margin-bottom: 60px;
    }
}
