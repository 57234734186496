.dropdown {

    &__title {
        .h4 {
            font-weight: 400;
            letter-spacing: 0;
        }
    }

    &__container {
        margin-top: 24px;
    }

    .dropdown-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        position: relative;

        th {
            color: $dark-gray;
            @include font-size(14, 17.5, 400);
            text-align: start;
            width: 125px;
            padding: 1px 8px 0;
            height: 48px;
            vertical-align: middle;
            text-wrap: balance !important;


            @include device-lg {
                width: 20%;
                height: auto;
                padding: 8px;
            }
        }

        td {
            @include font-size(18, 24.5, 400);

            text-align: start;
            padding: 8px;
            height: 48px;
            vertical-align: middle;

            @include device-lg {
                text-align: center;
                @include font-size(14, 19, 400);
            }
        }

        @include device-lg {
            tr td:nth-child(n + 7) {
                display: none;
            }

            tr th:nth-child(n + 7) {
                display: none;
            }

            th.only-mobile {
                display: table-cell !important;
            }

            tr.only-mobile {
                display: table-row !important;
            }

            tr td:last-child {
                display: table-cell !important;
            }
        }

        @include device-md {
            tr td:nth-child(n + 6) {
                display: none;
            }

            tr th:nth-child(n + 6) {
                display: none;
            }
        }

        @include device-sm {
            tr td:nth-child(n + 5) {
                display: none;
            }

            tr th:nth-child(n + 5) {
                display: none;
            }
        }

        &__data {
            tr:nth-child(2n) {
                background-color: $light-gray;
                /* Aplicar fondo gris a las filas pares a partir de la tercera fila */
            }

            tr td:first-child {
                text-align: start;
                /* O text-align: left; */
            }

            tr td:last-child {
                text-align: right;
            }

            tr:hover {
                cursor: pointer;

                .dropdown-table__data__chevron {
                    stroke: $green;
                }

                td {
                    background-color: $light-green-50;
                }
            }

            &__chevron {
                stroke: $dark-gray;
                vertical-align: middle;

            }

            &__product-name {
                font-weight: 700 !important;
                color: $black;
            }
        }

        &__headings {
            @include device-lg {
                box-shadow:
                    0px 2px 4px -2px rgba(16, 24, 40, 0.03),
                    0px 4px 8px -2px rgba(16, 24, 40, 0.05);
                background-color: $white;

                &.--sticky {
                    position: sticky;
                    top: 56px; // CTA Sticky
                }

                th {
                    border-right: 1px solid $medium-gray;
                }
            }

            @include device-lg('min') {
                th {
                    border-bottom: 1px solid $medium-gray;
                }
            }

            .vertical-header-container {
                @include device-lg {
                    transform: rotate(-180deg);
                    /* Rotar el texto verticalmente */
                    text-align: start;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 90px;
                    writing-mode: vertical-lr;
                }
            }

            .equipment-th {
                text-align: start;

                @include device-lg('min') {
                    width: 200px;
                }

                @include device-lg {
                    width: 30%;
                    border-right: 0 !important;
                }
            }

            .th-placeholder {
                width: 5%;
                text-align: right;
                padding: 0;
                border-right: 0;
                border-left: 1px solid $medium-gray;
            }

            .dropdown-table__line-btn {
                &.only-mobile {
                    height: 36px;

                    th {
                        margin: auto;
                        text-align: center;
                        border-top: 1px solid $medium-gray;
                        border-right: 0;
                    }
                }

                &.only-desk {
                    text-align: right;

                    &.dropdown-table__line-btn {
                        width: 125px;
                    }
                }
            }
        }

        .only-mobile {
            @include device-lg('min') {
                display: none;
            }
        }

        .only-desk {
            @include device-lg {
                display: none;
            }
        }
    }
}

// Acordeón

.accordion-dropdown-item {
    $root: &;

    &__summary,
    .accordion-dropdown-header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding-block: 20px;

        border-bottom: 1px solid $medium-gray;
        gap: 16px;

        .--text-m {
            font-weight: 400;
            transition: color $timming ease-in-out;
        }

        &:hover {
            .--text-m {
                color: $green;
            }
        }

        .--icon-circle {
            min-height: 28px;
            min-width: 28px;

            &.chevron-down,
            &.chevron-right {
                background-color: $green;
                border: 1px solid $green;
                color: $white;
                transition: all 300ms;

                svg {
                    padding: 4px;
                }

                &.--outlined {
                    color: $dark-gray;
                    border: 1px solid $green-light;
                    background-color: $white;
                    color: $dark-gray;

                    &:hover {
                        background-color: $green;
                        border: 1px solid $green;
                        color: $white;
                        transition: all 0.15s;
                    }
                }
            }
        }

        &__linepage-link {
            text-decoration: none;
        }
    }

    &__details {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 500ms;

        &__content {
            overflow: hidden;
        }
    }

    &.active {
        >#{$root}__summary {
            border-color: $green;

            .--text-m {
                color: $green;
                font-weight: 500;
            }

            &:hover {
                .--text-m {
                    text-decoration: none;
                }
            }

            .chevron-down {
                transform: rotate(-180deg); // Chevron hacia arriba
                background-color: $green;
                border: 1px solid $green;
                color: $white;
            }

            .chevron-rigth {
                background-color: $green;
                border: 1px solid $green;
                color: $white;
            }
        }

        >#{$root}__details {
            grid-template-rows: 1fr;
            padding-block-end: 24px;
        }
    }

    .showMoreDropdownButton {
        margin: auto;
        margin-top: 16px;
    }
}


// SIMPLE DROPDOWN
.simple-dropdown {
    .simple-dropdown__btn {
        padding: 0;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        transition: all 500ms;
        svg {
            transition: all 500ms;
        }
    }
    .simple-dropdown__content {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 500ms;
        & > ul {
            overflow: hidden;
        }
    }
    &.show {
        .simple-dropdown__content {
            grid-template-rows: 1fr;
        }
        .simple-dropdown__btn {
            color: $green;
            svg {
                transform: rotate(180deg);
            }
        }
        
    }
}
