.image-component {
    img {
        height: auto;
        max-width: 1124px;
    }
    
    &.scroll {
        overflow: auto;
    
        img {
            max-height: 790px;
            margin-right: auto;
            margin-left: auto;
            display: block;
        }
    }
    
    &.full {
        display: flex;
        justify-content: center;
    
        img {
            width: 100%;
            object-fit: contain;
        }
    }
}

