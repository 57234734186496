.projects {
    .h4 {
        margin-bottom: 40px;
        font-weight: 400;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }

    &__item {
        display: flex;
        flex-direction: column;

        .title {
            color: $green;
            @include font-size(24, 32.7, 400);
            margin-bottom: 8px;
        }
        
        .location {
            color: $green;
            @include font-size(16, 22, 700, -2);
            display: flex;
            align-items: center;

            svg {
                margin-right: 8px;
            }
        }

        .text {
            @include font-size(16, 22, 300);
            margin-top: 16px;
        }
    }

    @include device-lg {
        &__container {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include device-md {
        &__container {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}