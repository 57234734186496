.original-spare-parts {
    &-title-container {
        max-width: 57.5rem;

        margin: 0 auto;
        margin-bottom: 6.25rem;

        text-align: center;

        @include device-md("min") {
            margin-bottom: 5rem;
        }
    }

    h2 {
        margin-bottom: 1.5rem;

        @include device-md("min") {
            margin-bottom: 2.5rem;
        }
    }

    .two-columns {
        margin-top: 5rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 6rem;

        @include device-md("min") {
            flex-direction: row;

            gap: 1.875rem;
        }
    }
}
