.three-text-img {
    &__title {
        @include font-size(24, 30, 700);
        color: $green;
        margin-bottom: 52px;
        max-width: 1096px;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 31px;
    }

    &__box {
        display: flex;
        flex-direction: column;
        width: 100%;

        img {
            border-radius: 16px;
            width: 100%;
            object-fit: cover;
            aspect-ratio: 350 / 230;
        }

        div {
            margin-top: 20px;
            @include font-size(14, 20, 400);
        }
    }

    &__video {
        position: relative;
        cursor: pointer;
        .three-text-img__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            }
        }
  

    @include rwd(800) {
        &__container {
            gap: 15px;
        }
    }

    @include device-md {
        &__title {
            margin-bottom: 29px;
        }
        
        &__container {
            grid-template-columns: 1fr;
            gap: 31px;
        }
    }
    
    &__modal {
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: 99;
            top: 0;
            left: 0;
            display: none;
            justify-content: center;
            align-items: center;
            transition: display .3s ease allow-discrete;
            &.is_open{
                display: flex;
                .three-text-img__modal__overlay {
                    opacity: 1;
                    @starting-style {
                        opacity: 0;
                    }
                }
                .three-text-img__modal__content {
                    scale: 1;
                    display: block;
                    scale: 1;
                    transition: scale .3s ease-in-out;
                    @starting-style {
                        scale: 0;
                    }
                }
            }
        
            .three-text-img__modal__overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.639);
                backdrop-filter: blur(5px);
                opacity: 0;
                transition: opacity 1s;
            }
            &__container {
                width: min(100%, 1000px);
                padding: 0 12px;
                
                .three-text-img__modal__content{
                    scale: 0;
                    display: hidden;
                    background-color: white;
                    border-radius: 16px;
                    overflow: hidden;
                    width: 100%;
                    padding-bottom: 56.25%; /* 16:9 aspect ratio */
                    position: relative;
                    transition: scale .3s ease, display .3s ease allow-discrete;
                    & >  :first-child {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    }
                }
            }
            .three-text-img__modal__close {
                color: white;
                font-weight: 700;
                border-radius: 50%;
                border: 2px solid white;
                width: 40px;
                height: 40px;
                position: fixed;
                top: 20px;
                right: 20px;
            }
        
        }
    }