.text-img-bullets {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 47px;
        align-items: center;
    }

    &__text-and-bullets {
        .h4 {
            margin-bottom: 24px;
            max-width: 445px;
            &.--text-green {
                color: $green;
            }
        }

        .--text-body-s {
            max-width: 445px;
        }
    }

    &__container {
        margin-top: 80px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;

        .--text-m {
            margin-left: 24px;
            font-weight: 400;
        }
    }

    &__content {
        display: flex;
        align-items: center;
    }

    .green-circle {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        background-color: $green;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;

        p {
            color: $white;
            text-align: center;
            @include font-size(20, 27.28, 700, 0)
        }
    }

    @include device-lg {
        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            img {
                max-width: 100%;
                margin-bottom: 40px;
            }
        }

        &__text-and-bullets {
            .h4 {
                margin-bottom: 16px;
                max-width: 100%;
            }
    
            .--text-body-s {
                max-width: 100%;
            }
        }

        &__container {
            margin-top: 40px;
        }
    }
    
    @include device-md {
        &__container {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    &.--image-right {
        .text-img-bullets{
            &__img{
                order: 2;
            }
            &____text-and-bullets {
                order: 1;
            }
        }
      
    }
}