.error-ok {
    .two-columns {
        margin-top: 5rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 6rem;

        @include device-md("min") {
            flex-direction: row;
            align-items: stretch;

            gap: 1.875rem;
        }
    }
}
