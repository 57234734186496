#react-portal-modal-container {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 112;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;

    .react-modal {
        margin: 0 8px;
        width: min(100%, 800px);
        background-color: white;
        border-radius: 16px;
        padding: 20px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        animation: fadeIn 0.3s ease-in-out;

        .react-modal__modal-title {
            font-size: 32px;
            margin: 0;
            font-weight: 700;
            color: #2D2D2D;
            padding-bottom: 20px;
        }

        .react-modal__modal-body {
            white-space: pre-wrap;
            font-size: 16px;
            line-height: 22px;
        }

        .react-moda__buttons {
            display: flex;
            justify-content: flex-end;
           
        }

        .react-modal__close {
            position: absolute;
            top: 10px;
            right: 15px;
            cursor: pointer;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        } 
    }
}

