.message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    img,
    .h3 {
        margin-bottom: 1.5rem;
    }

    p {
        margin-bottom: 2rem;
        @include font-size(20, 27.3, 400);
    }

    .h3, p {
        max-width: 540px;
    }

    @include device-md {
        .h3 {
            @include font-size(32, 40, 700);
        }

        p {
            @include font-size(18, 24.6, 400);
        }
    }
}
