.text-img-fifty-fifty {

    .wrapper {
        display: flex;
    }

    &.left {
        .wrapper {
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;

            .text-img-fifty-fifty__image, .text-img-fifty-fifty__video, .text-img-fifty-fifty__model3d {
                height: auto;
                display: block;
                margin-right: 72px;
                &.--mobile-hidden {
                    @include device-md(){
                        display: none;
                    }
                }
                @include device-lg(){
                    margin-right: 30px;
                }
            }

            .text-img-fifty-fifty__model3d {
                max-width: 50%;
            }

        }
    }

    &.right {
        .wrapper {
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .text-img-fifty-fifty__image, .text-img-fifty-fifty__video, .text-img-fifty-fifty__model3d {
                margin-left: 72px;
                @include device-lg(){
                    margin-left: 30px;
                }
            }

            .text-img-fifty-fifty__model3d {
                max-width: 50%;
            }
        }
    }

    &__text {
        max-width: 445px;

        .h4 {
            margin-bottom: 24px;
        }

        .--text-s {
            font-weight: 400;

            ul {
                list-style-type: disc;
                padding-left: 20px;
            }
        }
    }

    &__video {
        position: relative;
        overflow: hidden;
        background-color: #000;
        width: 400px;
        height: 309px;
        border-radius: 10px;

        @include device-lg(){
            width: 350px;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    @include rwd(750) {
        &.left, &.right {
            .wrapper {
                flex-direction: column-reverse;
                align-items: start;
                .text-img-fifty-fifty__image, .text-img-fifty-fifty__video, .text-img-fifty-fifty__model3d {
                    margin-left: 0;
                    margin-right: 0;
                }
                .text-img-fifty-fifty__model3d {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }

        &__text {
            max-width: 100%;
            margin-top: 24px;
            width: 100%;
        }

        &__video {
            width: 100%;
            height: auto;
            aspect-ratio: 2;
            object-fit: cover;
        }
    }

    &__image {
        width: 100%;
        max-width: 400px;
        height: 309px;
        object-fit: cover;
        border-radius: 10px;

        @include device-lg(){
            width: 350px;
        }

        &.--cover-disabled {
            max-width: 400px;
            object-fit: unset;
            aspect-ratio: unset;
            height: unset;

            @include device-lg(){
                max-width: 350px;
            }
        }
        @include rwd(750) {
            width: 100%;
            max-width: 100%;
            aspect-ratio: 2;
            object-fit: cover;
            height: unset;
            &.--cover-disabled {
                max-width: 100%;
                width: 100%;
                object-fit: unset;
                aspect-ratio: unset;
                height: unset;
            }
        }
    }

    &__cta {
        margin-top: 24px !important;
        display: flex;
    }
}
