.h1 {
    @include font-size(68, 76, 700, -2);
    text-wrap: balance;
}

.h2 {
    @include font-size(52, 58, 700, -2);
    text-wrap: balance;
}

.h2-light {
    @include font-size(52, 58, 400, -2);
    text-wrap: balance;
}

.h3 {
    @include font-size(40, 50, 700, -2);
    text-wrap: balance;
}

.h3-regular {
    @include font-size(40, 50, 400, -2);
    text-wrap: balance;
}

.h4 {
    @include font-size(32, 40, 700, -2);
    text-wrap: balance;
}

.h4-regular {
    @include font-size(32, 40, 400, -2);
    text-wrap: balance;
}

.h5 {
    @include font-size(24, 32.7, 700, -2);
    text-wrap: balance;
}

.h6 {
    @include font-size(20, 27, 700);
    text-wrap: balance;
}
