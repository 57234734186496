.video-title-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 65px;

    &__title {
        @include font-size(24, 30, 700);
        color: $green;
        margin-bottom: 20px;
        max-width: 428px;
    }

    &__text {
        @include font-size(16, 24, 400);
        max-width: 475px;
    }

    &__video {
        position: relative;
        overflow: hidden;
        background-color: #000;
        width: 100%;
        aspect-ratio: 570/323;
        border-radius: 16px;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    @include device-lg {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 17px;

        &__text, &__title {
            max-width: 100%;
        }
    }

    @include device-md {
        &__title {
            @include font-size(18, 28, 700);
        }

        &__text {
            @include font-size(14, 20, 400);
        }
    }

    @include device-sm {
        &__video {
            width: 100%;
        }
    }
}