.modal {
    &.modal-feedback {
        position: fixed;
        top: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.8);
        height: 100%;
        width: 100%;
        border: none;
    
        .modal__box {
            display: flex;
            flex-flow: column nowrap;
            gap: 3rem;
            background-color: $white;
            border: 1px solid $medium-gray;
            border-radius: 10px;
            max-width: 980px;
            padding: 60px 30px 30px;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
    
            .modal__close {
                color: $green;
                position: absolute;
                top: 20px;
                right: 20px;
            }
    
            &__content {
                .product {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
    
                    img {
                        height: 386px;
                        width: 256px;
                        object-fit: cover;
                    }
    
                    &__text {
                        max-width: 634px;
                        margin-left: 30px;
    
                        .--text-s {
                            font-weight: 400;
                            letter-spacing: 0.02em;
    
                            p {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
    
            &__footer {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;
                @include device-md {
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    
        &.--small {
            .modal__box {
                max-width: 600px;
            }
        }
    
        @include device-sm {
            padding: 0;
    
            .modal__box {
                height: 100%;
                max-width: none;
                border-radius: 0;
    
                &__content {
                    flex-grow: 1;
                    padding-block-start: 50px;
                }
    
                &__footer {
                    flex-direction: column;
                    gap: 1rem;
    
                    .btn {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
        .content {
            max-width: 390px;
            margin: auto;
        
            p {
                @include font-size(18, 25);
            }
        }
    }
}
