.spare-parts-selector {
    background-color: $light-gray;
    &-container {
        border-radius: 10px;
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        // max-width: 950px;
        margin: auto;
        @include device-md {
            flex-direction: column;
        }
    }
    &__select-field {
        display: flex;
        gap: 16px;
        flex-shrink: 0;
        @include device-md {
            flex-direction: column;
            gap: 12px;
            width: 100%;
            .--select {
                width: 100% !important;
            }
            .btn {
                width: 100%;
            }
        }
    }
}