.cards-downloads {
    &__cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    @include device-lg {
        &__cards {
            grid-template-columns: 1fr 1fr;
        }
    }

    @include device-md {
        &__cards {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .card-download {
                margin-bottom: 20px;
            }
        }
    }

    @include device-md {
        &__title {
            @include font-size(24, 32.74, 400, 0);
        }
    }
}