.slider-text-img {

    &__title {
        margin-bottom: 40px;
        color: $green;

        &.center {
            text-align: center;
        }

        &.left {
            text-align: left;
        }
    }

    &__swiper {
        padding-bottom: 40px !important;
        .slider-text-img__controls {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 25px;
            .swiper-pagination {
                position:unset;
            }
            .swiper-pagination-bullet-active {
                background-color: $green;
            }
            
            .swiper-pagination-horizontal {
                bottom: 0px !important;
            }
    
            .swiper-button-next, .swiper-button-prev {
                flex-shrink: 0;
                position: unset !important;
                width: 32px;
                height: 32px;
                background: rgba(45, 45, 45, 0.85);
                border-radius: 50%;
                position: relative;
                margin-top: 0;
                &::after {
                    font-size: 8px;
                    color: $white;
                }
            }
    
            // .swiper-button-next {
            //     margin-right: 56px;
            // }
    
            // .swiper-button-prev {
            //     margin-left: 56px;
            // }
        }
    
    }

    &__item {
        display: flex !important;
        border-radius: 10px;
        background-color: $white;
        overflow: hidden;
        width: 100%;
        & > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__image {
        width: 100%;
        max-height: 75vh;
        object-fit: cover;
        aspect-ratio: 16/9;
    }

    &__text {
        padding: 40px;

        .--text-body-m {
            max-width: 786px;
            margin-left: auto;
            margin-right: auto;
        }

        .--text-body-xl {
            margin-bottom: 12px;
            text-align: center;
        }
    }

    @include device-md {
        &__title {
            margin-bottom: 25px;

            p {
                @include font-size(24, 32.7, 700, 0);
            }
        }
        &__swiper {
            padding-bottom: 35px !important;

            // .swiper-button-next {
            //     margin-right: 17px;
            // }
    
            // .swiper-button-prev {
            //     margin-left: 17px;
            // }
        }

        &__text {
            padding: 25px 0;

            .--text-body-m {
                width: 345px;
                @include font-size(14, 19, 400, 0)
            }

            .--text-body-xl {
                margin-bottom: 25px;
                @include font-size(18, 24.6, 700, 0)
            }
        }
    }
}