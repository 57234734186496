body.--hidden-breadcrumb {
    .hero-subcategory {
        @include device-lg("min"){
            margin-top: 56px; // Coincide con la altura de los breadcrumb
        }
    }
}

.hero-subcategory {
    margin-bottom: 0px;
    padding-bottom: 0;

    &__container {
        background: linear-gradient(90deg, #008657 0%, #005437 100%);
        color: $white;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        padding-left: 40px;
    }

    &__title-and-text {
        .h3 {
            margin-bottom: 4px;
        }
    }

    &__image {
        width: 460px;
        height: 160px;
        object-fit: cover;
    }

    @include device-lg {
        background: linear-gradient(90deg, #008657 0%, #006C46 99.99%);
        padding-top: 24px;
        padding-bottom: 24px;

        &__container {
            flex-direction: column;
            border-radius: 0;
            background: none;
            padding-left: 0;
            align-items: flex-start;

            .h3 {
                @include font-size(32, 40, 700, -2);
                margin-bottom: 0;
            }
        }

        &__image {
            display: none;
        }
    }
}