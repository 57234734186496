.bullet-title-and-description {
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        padding-top: 26px;
        row-gap: 79px;
    }

    &__content {
        width: 50%;
        display: flex;

        &.--grid-cols-3 {
            @include device-lg("min") {
                width: 33%;

                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                gap: 1rem;
            }
        }


        .green-circle {
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
            background-color: $green;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 3px;

            p {
                color: $white;
                text-align: center;
                @include font-size(20, 27.28, 700, 0)
            }
        }
    }

    &__text {
        margin-left: 24px;
        max-width: 448px;
        width: 80%;

        .--text-m {
            margin-bottom: 8px;
            letter-spacing: -0.02em;
        }

        .--text-s {
            letter-spacing: -0, 02em;
            font-weight: 400;
        }
    }

    @include device-lg {
        flex-direction: column;

        &__container {
            row-gap: 32px;
        }

        &__content {
            width: 100%;
        }

        &__text {
            max-width: 100%;
        }
    }
}