.most-searched-products {
    .h4 {
        margin-bottom: 2.5rem;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;

        @include device-lg("max") {
            grid-template-columns: repeat(2, 1fr);
        }
        
        @include device-md("max") {
            grid-template-columns: 1fr;
        }
    }
}
