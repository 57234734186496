.--text-m {
    @include font-size(20, 27.28, 700);
}

.--text-s {
    @include font-size(16, 22, 400);
}

.--text-xs {
    @include font-size(14, 22, 400);
}

.--text-l {
    @include font-size(24, 32, 400);
}

.--text-body-xl {
    @include font-size(24, 32.7, 400);
}

.--text-body-l {
    @include font-size(20, 27.3, 400);
}

.--text-body-m {
    @include font-size(18, 24.6, 400);
}

.--text-body-s {
    @include font-size(16, 22, 400);
}

.--text-body-xs {
    @include font-size(14, 19, 400);
}

.--title-s {
    @include font-size(32, 43.65, 400, 0);
    margin-bottom: 32px;

    @include device-md {
        @include font-size(24, 32.74, 400, 0);
    }
}

.--title-xs {
    @include font-size(24, 32.7, 700, -2);
    margin-bottom: 32px;
    color: $green;
}

.--text-light {
    font-weight: 300;
}

.--text-regular {
    font-weight: 400;
}

.--text-medium {
    font-weight: 500;
}

.--text-semibold {
    font-weight: 600;
}

.--text-bold {
    font-weight: 700;
}
