body.--hidden-breadcrumb {
    .hero-product {
        @include device-lg("min"){
            margin-top: 56px; // Coincide con la altura de los breadcrumb
        }
    }
}

.hero-product {
    margin-bottom: 0;
    padding-bottom: 0;
    
    &__container {
        background: linear-gradient(90deg, #008657 0%, #005437 100%);
        color: $white;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        padding: 25.5px 0px 25.5px 40px;
        min-height: 120px;

        .--text-body-l {
            max-width: 527px;
            margin-top: 4px;
        }
    }

    @include device-lg {
        background: linear-gradient(90deg, #008657 0%, #006C46 99.99%);
        padding-top: 24px;
        padding-bottom: 24px;

        &__container {
            flex-direction: column;
            border-radius: 0;
            background: none;
            padding-left: 0;
            align-items: flex-start;
            padding-top: 0px;
            padding-bottom: 0px;
            min-height: 0;
            
            .h3 {
                @include font-size(32, 40, 700, -2);
            }
        }
    }
}