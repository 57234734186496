.cta-promotional {
    background: linear-gradient(90deg, #212121 20.23%, #6b6b6b 71.57%);
    color: $white;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 24px;
    @include main-grid;

    &__content {
        grid-column: 3 / span 7;

        .h4 {
            font-weight: 500;
        }

        .--text-s {
            margin-bottom: 20px;
            margin-top: 10px;
        }

        .btn {
            width: 255px;
        }
    }

    &__title {
        @include font-size(35, 42, 400);
    }

    &__image {
        width: 100%;
        max-width: 470px;
        height: 290px;
        object-fit: contain;
        grid-column: 14 / span 7;
    }

    @include device-lg {
        padding-top: 42px;
        padding-bottom: 42px;

        &__content {
            grid-column: 2 / span 5;
            grid-row: 1px;
        }

        &__image {
            grid-column: 7 / span 4;
            grid-row: 1px;
            width: 100%;
            height: 210px;
        }
    }

    @include rwd(850) {
        display: flex;
        flex-direction: column;
        border-radius: 0;
        background: linear-gradient(180deg, #212121 0%, #6B6B6B 100%);

        &-wrapper {
            padding-left: 0;
            padding-right: 0;
        }


        &__content {
            margin-left: 15px;
            margin-right: 15px;
            text-align: center;
            padding-top: 60px;
            padding-bottom: 43px;

            .btn {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 60px;
            }
        }

        &__image {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}
