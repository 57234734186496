.recommender {
    &__header {
        background-color: $green;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .h4 {
            color: $white;
        }
    }
    &__title {
        display: flex;

        flex-direction: column;

        gap: .5rem;

        margin: 1rem 0;
        margin-top: 40px;

        @include device-md("min") {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .--text-body-s {
            color: $dark-gray;
        }

        &.--column {
            @include device-md("min") {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }
    }
}
