.slider-landings {


    &__top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        
        .h3-regular {
            margin-bottom: 0px;
        }
    }

    &__item {
        height: auto !important;
    }

    &__nav-pag {
        position: relative;
        margin-top: 25px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85px;
        gap: 20px;

        .swiper-button-next::after,
        .swiper-button-prev::after {
            font-size: 16px;
            color: $black;
            margin: 0;
            @include font-size(14, 19, 700, 0);
        }
        
        .swiper-button-next,
        .swiper-button-prev {
            position: initial !important;
            border: 1px solid #6F6E6F;
            max-width: 42px;
            max-height: 42px;
            min-width: 42px;
            min-height: 42px;
            border-radius: 50%;
        }
    }

    @include rwd(900) {
        padding-right: 0;
    }

    @include device-md {
        &__top-container {
            margin-bottom: 24px;

            .h3-regular {
                @include font-size(20, 20, 400, 0);
                margin-bottom: 0;
            }

            .slider-landings__nav-pag {
                display: none;
            }
        }
    }
}