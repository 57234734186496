.form-contact {
    .h4 {
        margin-bottom: 8px;
        font-weight: 300;


        strong {
            font-weight: 600;
        }
    }

    .subtitle {
        @include font-size(18, 24.55, 300);
    }

    form {
        margin-top: 40px;

        display: flex;
        justify-content: space-between;

        .form-contact__inputs {
            width: 75%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 23px;
            row-gap: 32px;
            margin-right: 24px;
            height: min-content;

            .form__field {
                width: 100%;
            }

            .--textarea {
                grid-column: 1 / -1;
                width: 100%;
                height: 100%;
                margin-bottom: 0;

                .form__control {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .form-contact__checks {
            width: 25%;

            .form__field {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 7px;

                &>.checkLabelHead {
                    @include font-size(15, 21, 300);
                    color: $green;
                    border-bottom: 1px solid #e5e5e5;
                    width: 100%;
                    margin-bottom: 8px;
                    margin-top: 37px;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }

                &:first-of-type {

                    margin-bottom: 40px;
                }
            }

            .btn {
                width: 100%;
                margin-top: 28px;
            }
        }

        span.col {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column: 1 / -1;
            column-gap: 23px;
            row-gap: 32px;

            .phone1 {
                grid-column: 1 / 2;
                grid-row: 1;
                display: flex;
                gap: 10px;
            }

            .phone2 {
                grid-column: 3 / -1;
                grid-row: 1;
                display: flex;
                gap: 10px;
            }

            .form__field:last-child {
                grid-column: 2 / 3;
                grid-row: 1;
            }
        }

        select {
            padding-right: 24px !important;
        }
    }

    @include rwd(1200) {
        form {
            .form-contact__inputs {
                grid-template-columns: 1fr 1fr;
                width: 60%;
                column-gap: 10px;

                span {
                    column-gap: 10px;
                    grid-template-columns: repeat(2, 1fr);

                    .phone1 {
                        grid-row: 1;
                        grid-column: 1;
                    }

                    .phone2 {
                        grid-row: 1;
                        grid-column: 2;
                    }

                    .form__field:last-child {
                        grid-row: 2;
                        grid-column: 1;
                    }

                }

                .contactEmailInput {
                    grid-row: 3;
                }
            }

            .form-contact__checks {
                width: 40%;
            }
        }
    }

    @include device-lg {
        form {
            flex-direction: column;
            // max-width: 700px;
            margin-left: auto;
            margin-right: auto;

            .form-contact__inputs {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-right: 0;

                .form__field {
                    // width: 343px;

                    &.--textarea {
                        margin-bottom: 38px;
                        width: 100%;

                        .form__control {
                            height: 160px;
                        }
                    }
                }

                span {
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr 1fr;
                    column-gap: 10px;

                    .phone1 {
                        grid-row: 1;
                        grid-column: 1;
                    }

                    .phone2 {
                        grid-row: 1;
                        grid-column: 2;
                    }

                    .form__field:last-child {
                        grid-row: 2;
                        grid-column: 1 / -1;
                    }
                }
            }

            .form-contact__checks {
                width: 100%;
            }
        }
    }

    @include rwd(600) {
        form {
            .form-contact__inputs {
                span {
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr 1fr 1fr;

                    .phone1 {
                        grid-row: 1;
                        grid-column: 1;
                    }

                    .phone2 {
                        grid-row: 3;
                        grid-column: 1;
                    }

                    .form__field:last-child {
                        grid-row: 2;
                        grid-column: 1;
                    }
                }
            }
        }
    }
}