.image-full-view {
    display: flex;
    justify-content: space-between;

    .--text-m {
        letter-spacing: -0.02em;
        margin-bottom: 25px;
    }

    span {
        position: relative;
    }

    img {
        max-width: 540px;
        max-height: 240px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        transition: all $timming ease-in-out;

        &:hover {
            filter: brightness(0.8);
            cursor: pointer;
        }
    }

    button {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        max-width: 48px;
        max-height: 48px;
        min-width: 48px;
        min-height: 48px;
        background: rgba(45, 45, 45, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        position: absolute;
        bottom: 20px;
        right: 11px;
    }

    @include device-lg {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;

        .--text-m {
            align-self: flex-start;
        }
    }
}

#modal-image1, #modal-image2 {
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, .8);
    height: 100vh;
    width: 100vw;
    cursor: pointer;
    border: none;
    
    .modal__box {
        border-radius: 10px;
        max-width: 95vw;
        height: auto;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .modal__close {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            max-width: 48px;
            max-height: 48px;
            min-width: 48px;
            min-height: 48px;
            background: rgba(45, 45, 45, 0.85);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            position: absolute;
            top: 30px;
            right: 30px;
        }

        &__content {
            img {
                width: 95vw;
                height: auto;
                object-fit: contain;
            }
        }
    }

    @include device-lg {
        .modal__box {
            .modal__close {
                top: 10px;
                right: 0px;
            }
        }
    }
}