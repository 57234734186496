.quality {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .--title-s {
        align-self: flex-start;
        margin-bottom: 40px;
    }
    
    &__image {
        overflow: auto;
        width: 100%;

        .first {
            max-width: 980px;
            max-height: 604px;
        }
    }

    &__images {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 49px;
        width: 100%;
        margin-top: 40px;

        .second {
            max-width: 502.06px;
            max-height: 242.66px;
        }

        .third {
            max-width: 539px;
            max-height: 261px;
        }
    }

    @include device-lg {

        &__image {
            align-self: flex-start;
        }

        &__images {
            flex-direction: column;

            img {
                width: 100%;
            }
        }
    }
}