.recommender-steps {
    border-bottom: 1px solid $light-gray;

    .steps-grid {
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
        font-weight: 600;

        .number,
        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border: 2px solid $medium-gray;
            background-color: $green;
            border-radius: 999px;
        }

        .number {
            display: none;
        }

        .icon svg {
            width: 19px;

            @include device-sm {
                width: 12px;
            }
        }

        .text {
            color: $black;
            text-align: center;
            line-height: 1.2;
        }

        &+.divider {
            background-color: $green;
        }

        &.--active.--active {
            .number {
                display: flex;
                color: $green ;
                background-color: rgba($green-light, 50%);
                border-color: $green;
            }

            .icon {
                display: none;
            }

            .text {
                color: $green;
            }

            // Disabled
            & ~ .step {
                .number {
                    color: $medium-gray;
                    border-color: $medium-gray;
                    background-color: transparent;
                    display: flex;
                }

                .icon {
                    display: none;
                }

                .text {
                    color: $dark-gray;
                }
            }

            & ~ .divider {
                background-color: $medium-gray;
            }
        }

        @include device-md {
            .text {
                display: none;
            }
        }

        @include device-sm {
            .number,
            .icon {
                width: 24px;
                height: 24px;
            }
        }
    }

    .divider {
        height: 2px;
        width: 100%;
        background-color: $medium-gray;
        margin-top: .875rem;
    }

    @include device-sm {
        .divider {
            margin-top: .7rem;
        }
    }
}
