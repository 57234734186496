.results {
    &__title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid $medium-gray;
        gap: 12px;

        & > .h4 {
            font-weight: 400;
            @include device-md {
                @include font-size(24, 32, 400)
            }
        }
    }

    .results-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    
        @include device-md {
            grid-template-columns: 1fr;
        }
    }
    
    &__cards-col {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__cards-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        border: 1px solid $medium-gray;
        padding: 16px;
        text-decoration: none;
        strong{
            font-size: 1rem;
        }
        &:hover {
            border-color: $green;
        }
    }

    &__cta-col {
        @include device-md("min"){
            display: flex;
            justify-content: flex-end;
        }
    }

    &__cta-box {
        padding: 12px;
        background-color: $light-gray;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-width: 255px;
        @include device-md{
            max-width: 100%;
        }
        .--text-s {
            font-weight: 700 
        }
    
        .--text-body-xs {
            color: $dark-gray;
        }
    }

}