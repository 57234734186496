//Colors
.--txt-green {
    color: $green;
}
.--txt-orange {
    color: $orange;
}

.--bg-green {
    color: $green;
}
.--bg-orange {
    color: $orange;
}
.--bg-gray-25 {
    background-color: $light-gray-25;
}

// Margins & Paddings
$max: 200;
$offset: 5;
$unit: 'px';
@mixin helperClassMaker($className, $styleName) {
    $i: 0;
    @while $i <= $max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }
        $i: $i + $offset;
    }
}

@include helperClassMaker('.--mt-', 'margin-top');
@include helperClassMaker('.--mr-', 'margin-right');
@include helperClassMaker('.--mb-', 'margin-bottom');
@include helperClassMaker('.--ml-', 'margin-left');

@include helperClassMaker('.--pt-', 'padding-top');
@include helperClassMaker('.--pr-', 'padding-right');
@include helperClassMaker('.--pb-', 'padding-bottom');
@include helperClassMaker('.--pl-', 'padding-left');

.--richtext {
    @include font-size(18, 28);

    b,
    strong {
        font-weight: 700;
    }

    a {
        color: inherit;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            text-decoration: none;
        }
    }

    p {
        margin-bottom: 1.5rem;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 2rem 0;
    }

    hr {
        display: block;
        margin: 1.5rem 0;
        border-top: 1px solid $medium-gray;
        border-bottom: 0;

        & ~ p {
            margin-top: 2rem;
        }
    }

    ul {
        padding-left: 25px;
        list-style: disc;

        li {
            margin-bottom: 24px;
            padding-left: 16px;

            @include device-md('min') {
                padding-left: 20px;
            }
            @include device-lg('min') {
                padding-left: 40px;
            }
        }
    }

    @include device-xl {
        @include font-size(16, 24);
    }

    @include device-sm {
        @include font-size(14, 20);
    }
}

.--center-text {
    text-align: center;
}

.--hidden {
    display: none !important;
}

.--icon-circle {
    color: $green;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    background-color: $light-green-50;
    display: flex;
    justify-content: center;
    align-items: center;
}

.--bold {
    font-weight: 700;
}

.--txt-decoration-none {
    text-decoration: none;
}

.--full-width-sm {
    @include device-sm {
        width: 100%;
    }
}