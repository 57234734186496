.richtext-policies {
    
    div {
        max-width: 909px;
        margin-left: auto;
        margin-right: auto;
    }

    ul {
        list-style: disc;
        list-style-position: inside;
        margin-bottom: 12px;

        li {
            @include font-size(16, 22, 400, -2);
        }
    }
    
    p {
        @include font-size(16, 22, 400, -2);
        margin-bottom: 12px;
    }
    
    h4 {
        @include font-size(32, 40, 700, -2);
        margin-top: 80px;
        margin-bottom: 24px;
        display: block;
    }

    @include device-md {
        strong {
            margin-top: 40px;
        }
    }
}