.image-text {
    background-color: $light-gray-25;
    padding-top: 40px;
    padding-bottom: 40px;

    h2 {
        text-align: center;
    }

    &__container {
        padding: 1rem;

        display: flex;

        justify-content: center;
        align-items: center;

        gap: 1rem;

        flex-direction: column;

        @include device-md("min") {
            flex-direction: row;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:last-child {
            margin-right: 0;
        }
    }

    &__text {
        max-width: 348px;

        .h5 {
            margin-bottom: 24px;
            color: $green;
        }

        .text {
            font-weight: 400;
            font-size: 16px;

            p {
                margin-bottom: 10px;
                @include font-size(16, 21.82, 400, -2);
            }
        }
    }

    &__image {
        background-color: $white;
        border: 2px $medium-gray solid;
        width: 100%;
        height: 230px;
        object-fit: contain;
        margin-bottom: 24px;
        border-radius: 16px;
        padding: 1rem;
    }

    // @include rwd(1220) {
    //     max-width: 100vw;

    //     &__container {
    //         display: none;
    //     }

    //     &__image {
    //         width: 100%;
    //         max-width: 348px;
    //         margin-left: auto;
    //         margin-right: auto;
    //         display: block;
    //         object-fit: cover;
    //     }

    //     &__text {
    //         margin-left: auto;
    //         margin-right: auto;
    //     }

    //     &__container__mobile {
    //         display: block;

    //         .swiper {
    //             padding-bottom: 60px;

    //             .swiper-slide {
    //                 // width: 100% !important;
    //                 margin-right: 0;
    //             }

    //             .swiper-pagination-bullet-active {
    //                 background-color: #ff9000;
    //             }
    //         }
    //     }
    // }

    @include device-md {
        margin-left: 0;

        &__image {
            max-width: 100%;
        }
    }
}
