.card-events {
    // max-width: 350px;
    width: 100%;
    border-radius: 16px;
    background-color: $white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-decoration: none;
    border: 2px solid $white;
    transition: all $timming ease-in-out;
    height: 173px;

    .date {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        color: $black;
        gap: 0;

        p {
            @include font-size(12, 16.37, 400, -1);
            margin-left: 8px;
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        width: 100%;

        div {
            display: flex;
            flex-direction: column;
        }
    }

    .--text-body-m {
        color: $green;
        margin-bottom: 8px;
    }

    .arrow {
        color: $green;
        align-self: flex-end;
    }

    &:hover {
        border: 2px solid $green;
    }
}
