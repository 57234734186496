.product-info-slider {
    @include main-grid;

    &__img {
        grid-column: 1 / span 12;
        width: 100%;
        max-height: 466px;
        border-radius: 20px;
        border: 2px solid $medium-gray;
        display: flex;align-items: center;
        justify-content: center;
        
        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    &__right {
        // margin-left: 30px;
        grid-column: 14 / -1;


        .h5 {
            letter-spacing: 0;
            color: $green;
        }

        .--text-s {
            &.category {
                font-weight: 700;
                padding-bottom: 24px;
                border-bottom: 1px solid $medium-gray;
                margin-bottom: 24px;
            }

            &.description {
                letter-spacing: -0.02em;
                margin-bottom: 40px;

                p {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__characteristics {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        div {
            width: 190px;
            margin-bottom: 20px;
        }

        .name {
            @include font-size(18, 25, 700);
            margin-bottom: 4px;
        }

        .--text-s {
            letter-spacing: -0.02em;
        }
    }

    @include device-lg {
        display: flex;
        flex-direction: column-reverse;

        &__img {
            width: auto;
        }

        &__characteristics {
            margin-bottom: 16px;
        }
    }
}
