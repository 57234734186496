.hero-spare-parts {
    position: relative;
    margin-right: auto;
    margin-left: auto;

    padding-top: 0;
    padding-bottom: 0;
    $root: &;
    .--boxed {
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    &.--img-without-bg {
        background: linear-gradient(86deg, $black 52%, rgba(109, 109, 109, 1) 90%);
        #{$root}__container {
            background-color: transparent;
        }
        
    }

    &:not(.--img-without-bg) {
        #{$root}__image {
            position: absolute;
            top: 0;
            right: 0;
            mask-image: linear-gradient(-92deg, $black 20%, rgba(0, 0, 0, 0) 99%);  
        }
    }

    &__container {
        background: $black;
        color: $white;
        position: relative;
        // z-index: 2;
        height: 355px;
        display: flex;
        align-items: center;
        padding: 0;
    }

    &__title > * {
        @include font-size(40, 50, 400);
        max-width: 540px;

        @include device-md {
            max-width: none !important;
        }
        
    }

    &__image {
        display: block;
        max-height: 355px;
        max-width: 100%;
    }

    @include device-md {
        &__container {
            background: $black;
            padding-left: 0;
            align-items: flex-start;
            padding-top: 0px;
            padding-bottom: 0px;
            height: 100%;
            padding: 2rem 0;
        }

        &__title {
            height: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            > * {
                @include font-size(32, 40, 400, -2);
                max-width: 540px;
                margin-bottom: 8px;
            }
        }

        &__image {
            display: none;
        }
    }
}
