#modal-form {
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, .8);
    height: 100%;
    width: 100%;
    border: none;
    overflow: auto;

    &.--open {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .modal__box {
        background-color: $white;
        border: 1px solid $medium-gray;
        border-radius: 10px;
        max-width: 600px;
        width: 100%;
        padding: 30px;
        position: relative;
        margin: auto;

        .modal__close {
            color: $green;
            position: absolute;
            top: 30px;
            right: 30px;
        }

        &__content {
            display: flex;
            flex-direction: column;

            .h5 {
                margin-top: 44px;
                margin-bottom: 40px;
                font-weight: 400;
                text-wrap: balance;
            }

            form {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 30px;
                row-gap: 32px;

                .form__field {
                    width: 255px
                }
            }

            .btn {
                width: 200px;
                align-self: flex-end;
                margin-top: 40px;
                margin-bottom: 23px;
            }
        }
    }

    @include device-md {
        .modal__box__content {
            form {
                display: flex;
                flex-direction: column;
            }

            .btn {
                width: 100%;
            }
        }
    }

    @include device-sm {
        padding: 0;

        .modal__box {
            border-radius: 0;
        }
    }

    #err-msg {
        display: none;
        color: $danger;
        font-size: 10px;
        font-weight: 700;
        grid-column: 1/-1;

    }
}
