:root {
    --w-color-primary: #027a48;
    --w-color-primary-200: #12b76a;
    --w-color-secondary: #008657;
    --w-color-secondary-600: #008657;
    --w-color-secondary-400: #027a48;
    --w-color-warning-100: #2d2d2d;
    --w-color-critical-200: #7a271a;
}

.massimport-listing thead th {
    text-align: center;
}

.massimport-listing {
    border-collapse: collapse;
    width: 100%;
    color: #008657;
}

.massimport-listing th,
.massimport-listing td {
    border: 1px solid #008657;
    padding: 8px;
    text-align: center;
}

.massimport-listing th {
    background-color: #008657;
    color: white;
}

.massimport-listing tbody tr:nth-child(even) {
    background-color: #cacfcd;
}


#wagtail {
    .footer .footer__container--hidden {
        background-color: transparent;
    }

    .sidebar-panel .c-page-explorer {
        background-color: #008657;
    }

    .sidebar-sub-menu-panel {
        background-color: #008657;
    }

    .chooser-search.search-bar {
        background-color: unset;
        margin-bottom: 24px;
    }

    .checkbox {
        padding-bottom: 4px;
        font-size: 1.125rem !important;
    }

    div[data-streamfield-stream-container=''] > div:nth-child(2n) {
        padding-top: 10px;
        padding-bottom: 1px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        border: 1px solid #e0e0e0;
    }
}


/* Estilos selector de íconos */

.icon-select {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 8px;
}

.icon-option {
    position: relative;
    margin: 5px;
}

.icon-option input[type="radio"] {
    display: none;
}

.icon-option label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.3s;
    padding: 5px;
    border-radius: 4px;
}

.icon-option input[type="radio"]:checked + label {
    border-color: #008657;
}

#btn-spare-part {
    max-width: 160px;
}