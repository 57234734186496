.text-list {
    &__container {
        margin-top: 63px;
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        padding-bottom: 40px;
        border-bottom: 1px solid #E9E9E9;
        margin-bottom: 40px;

        &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
        }

        .title {
            color: $green;
            width: 223px;
            margin-right: 65px;
        }

        .text {
            ul {
                display: grid;
                grid-template-columns: 380px 380px;

                li {
                    list-style-type: disc !important;
                    list-style-position: inside;
                    @include font-size(16, 22, 400, -2)
                }
            }

            p {
                @include font-size(16, 22, 400, -2);
                max-width: 790px;
            }
        }
    }

    @include device-lg {
        &__item {
            flex-direction: column;

            .title {
                margin-bottom: 8px;
                width: 100%;
            }

            .text {
                ul {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}