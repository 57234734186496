.cards-with-filter {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .subtitle {
        @include font-size(18, 24.55, 300);
    }

    &__controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .icons {
            display: flex;
            gap: 0.5rem;
        }

        @include rwd(700) {
            justify-content: flex-end;
            width: 100%;
        }
    }

    &__title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        @include rwd(700) {
            flex-direction: column;
            align-items: flex-start
        }
    }

    &__cards {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1.5rem;
        row-gap: 2rem;

        @include rwd(1200){
            grid-template-columns: repeat(3, 1fr);
            
        }
        
        @include device-md {
            grid-template-columns: repeat(2, 1fr);
        }
        
        @include device-sm {
            grid-template-columns: 1fr;
        }
        
        &.--three-cols {
            grid-template-columns: repeat(3, 1fr);
            @include device-md {
                grid-template-columns: repeat(2, 1fr);
            }
            @include device-sm {
                grid-template-columns: 1fr;
            }
        }

        .card-product {
            @include device-md("min") {
                flex: 1 1 45%;
            }

            @include device-lg("min") {
                flex: 1 1 30%;
            }
        }

    }
    // List view
    .--list-view {
        display: none;
        grid-template-columns: 1fr;
        row-gap: 1rem;
        
        .card-product {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            padding: 12px;
            gap: 12px;
            align-items: center;

            @include device-md("min") {
                flex-direction: row;
            }


            &__container {
                gap: 1rem;
                @include device-md("min") {
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            &__title-container {
                padding: 0;
            }

            &__buttons {
                margin-top: 0;
                gap: 1rem;

                @include device-md("min") {
                    flex-direction: row-reverse;
                }

                button {
                    min-height: unset;
                    @include font-size(14, 19, 700);
                    padding: 16px 24px;
                    border-radius: 8px;
                    border: 1px solid $light-gray;
                }
            }

            .card-product__image {
                padding: 5.5px;
                background-color: $white;
                border: 1px solid $medium-gray;
                border-radius: 4px;
                height: 65px;
                width: 65px;
                img {
                    width: 54px;
                    height: 54px;
                    object-fit: contain;
                }
                @include device-md() {
                    display: none
                }
            }
        }
    }
}
