.card-equipment {
    background-color: $white;
    border-radius: 16px;
    border: 1px solid #EDEDED;
    overflow: hidden;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__image {
        width: 380px;
        height: 189px;
        object-fit: cover;
    }

    &__text {
        color: $green;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
        margin-top: auto;
        margin-bottom: auto;

        .--text-body-xs {
            color: $black;
            margin-top: 6px;
            margin-bottom: 12px;
        }

        svg {
            align-self: flex-end;
        }
    }

    @include device-md {

        &__image {
            width: 100%;
            height: 189px;
        }

        &__text {
            padding: 16px;
            
            .--text-body-m {
                @include font-size(16, 22, 700, -2);
            }
        }
    }
}