.card-dash {
    height: 110px;
    width: 730px;
    border-radius: 10px;
    background-color: $white;
    position: relative;
    overflow: hidden;
    padding: 27px 40px 27px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    transition: all $timming ease-in-out;
    border: 1px solid $medium-gray;

    svg {
        flex-shrink: 0;
        margin-left: 30px;
    }

    &__bar {
        height: 100%;
        width: 18px;
        background-color: $green;
        position: absolute;
        top: 0;
        left: -11px;
    }

    &__title {
        .--text-s {
            letter-spacing: -0.02em;
            font-weight: 400;
            color: $dark-gray;
        }
    }

    
    &:hover {
        border: 1px solid $green;
        
        .h5 {
            color: $black;
        }
        @include device-lg("min"){

            padding-right: 30px;
        }
    }

    @include device-lg {
        width: 100%;
    }

    @include device-md {
        padding: 10px 20px;
        min-height: 92px;
        height: auto;

        .card-dash__title {
            .h5 {
                @include font-size(18, 24.55, 700, 0)
            }
    
            .--text-s {
                @include font-size(14, 19.1, 400, 0)
            }
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }
}