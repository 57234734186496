.form-checkout {
    &.--form-width {
        max-width: 800px;
        margin: 0 auto;
        padding: 4rem 2rem;
    }

    .h5 {
        margin-bottom: 2rem;
    }

    .form__step {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        column-gap: 1.875rem;
        row-gap: 2rem;

        @include device-md("min") {
            flex-direction: row;
        }

        .form__field {
            width: auto;
            flex: 1 1 100%;

            @include device-md("min") {
                flex: 1 1 45%;

                &.--fill-width {
                    flex: 1 1 100% !important;
                }
            }
        }

        .btn {
            margin-right: auto;
            margin-left: 0;
        }
    }

    .form__step:not(.--active) {
        display: none;
    }

    .steps {
        display: flex;
        margin-bottom: 2rem;

        &__item {
            position: relative;
            color: $medium-gray;
            flex: 1 1 50%;

            display: flex;
            flex-direction: column;
            align-items: center;

            @include device-md("min") {
                padding-right: 3.125rem;
                flex: 0 1 auto;
            }

            &:not(:last-of-type)::after {
                content: "";
                position: absolute;
                display: block;
                top: 12px;
                left: 25%;
                width: 100%;
                height: 3px;
                background-color: currentColor;

               
                @include device-md {
                    left: 50%;
                }
            }

            &__number {
                position: relative;
                background-color: $white;
                border: 2px solid $medium-gray;
                color: $dark-gray;
                padding: 0.4rem;
                margin-bottom: 0.25rem;
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                transition: all 0.3s ease-in-out;
                z-index: 1;
            }

            &__label {
                color: $dark-gray;
                transition: all 0.3s ease-in-out;
                font-weight: 400;
            }

            &.--validated {
                color: $green;
                transition: all 0.3s ease-in-out;

                .steps__item__number {
                    background-color: $green;
                    border-color: $green;
                    color: $white;
                }

                .steps__item__label {
                    color: $green;
                    font-weight: 700;
                }
            }

            &.--active {
                transition: all 0.3s ease-in-out;
                color: $medium-gray;

                .steps__item__number {
                    background-color: $light-green-50;
                    color: $green;
                    border: 2px solid $green;
                }

                .steps__item__label {
                    color: $green;
                    font-weight: 700;
                }
            }
        }
    }

    .is-grouped {
        display: flex;
        gap: 1rem;
    }

    // Override styles
    .form__field.--textarea {
        width: 100%;
        flex: 1 1 100%;

        textarea {
            width: 100%;
        }
    }

    input[type="file"] {
        display: none;

        & ~ label {
            display: flex !important;
            color: $dark-gray;
            flex-direction: row;
            justify-content: space-between;
            cursor: pointer;

            .attach {
                @include font-size(12, 16, 700);
                color: $green;
                text-decoration: underline;
            }
        }
    }

    .file-list {
        margin-top: 0.5rem;

        .file-item {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        img {
            display: block;
        }

        .remove-list {
            cursor: pointer;
            margin-left: auto;
            margin-right: 0;
        }
    }
}
