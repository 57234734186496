.diagram {

    display: flex;
    flex-direction: column;

    img {
        max-width: 665.85px;
        max-height: 743.93px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 19px;
        width: 100%;
    }
}