.card-product {
    background-color: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    outline: 1px solid $medium-gray;
    transition: all $timming ease-in-out;

    &__image {
        background-color: $light-gray;
        padding: 24px;
        transition: all $timming ease-in-out;

        img {
            width: 100%;
            height: 200px;
            object-fit: contain;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .title {
            @include font-size(18, 24.55, 700);
        }

        .--text-xs {
            line-height: 19.1px;
            color: $dark-gray;
            margin-top: 4px;
        }
    }

    &__title-container {
        padding: 24px;

    }

    &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;

        align-items: center;


        &__details {
            min-height: 56px;
            width: 100%;
            border-top: 1px solid $medium-gray;
            border-right: 0.5px solid $medium-gray;

            @include font-size(16, 22, 700);
            color: $dark-gray;

            &:hover {
                background-color: $light-gray;
            }
        }
        
        &__add {
            min-height: 56px;
            width: 100%;
            border-top: 1px solid $medium-gray;
            border-left: 0.5px solid $medium-gray;

            @include font-size(16, 22, 700);
            color: $green;

            &:hover {
                background-color: $light-gray;
            }
        }

        &__remove {
            display: none;
            min-height: 56px;
            width: 100%;

            border-top: 1px solid $medium-gray;
            border-left: 0.5px solid $medium-gray;
            @include font-size(16, 22, 700);
            color: $danger;

            &:hover {
                background-color: $light-gray;
            }
        }

        .cotizar {
            svg {
                pointer-events: none;
            }
        }
    }

    &.--selected {
        outline: 2px solid $green;

        &:hover {
            outline: 2px solid $green;
        }

        .cotizar {
            display: none;
        }

        .card-product__buttons__remove {
            display: block
        }

        &:hover {
            .card-product__image {
                background-color: $light-gray;
            }
        }
    }

    .modal-btn-detail {
        svg {
            pointer-events: none;
        }
    }

    &:hover {
        outline: 1px solid $green;

        .card-product__image {
            background-color: $white;
        }
    }
}
