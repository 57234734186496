.title-bullets-subtitle-text {
    &__title {
        @include font-size(24, 30, 700);
        color: $green;
        margin-bottom: 30px;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 12px;
        margin-right: auto;
        margin-left: auto;

        &.left {
            .title-bullets-subtitle-text__content {
                text-align: left;
                align-items: flex-start;
            }
        }

        &.center {
            .title-bullets-subtitle-text__content {
                text-align: center;
                align-items: center;
            }
        }
    }

    &__content {
        max-width: 350px;
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;

        .--icon-circle {
            max-width: 72px;
            max-height: 72px;
            min-width: 72px;
            min-height: 72px;
            margin-bottom: 22px;
            padding: 0.5rem;
        }

        .subtitle {
            @include font-size(20, 30, 700);
            margin-bottom: 10px;
            margin-top: 22px;
        }
    }

    @include rwd(800) {

        &__title {
            @include font-size(18, 28, 700);
            color: $green;
            margin-bottom: 26px;
        }

        &__container {
            grid-template-columns: 1fr;
            gap: 0;

            &.left, &.right {
                .title-bullets-subtitle-text__content {
                    text-align: center;
                    align-items: center;
                }
            }
        }

        &__content {
            max-width: none;
            width: 100%;
            margin-bottom: 32px;

            .--icon-circle {
                margin-bottom: 0;
            }
        }
    }
}
