.categories-list {
    $root: &;
    &__title {
        padding-block-end: 80px;
        @include device-md {
            &.h4-regular {
                @include font-size(30);
            }
        }
    }
    &-box {
        align-items: start !important;
        row-gap: 80px;
        @include main-grid();
        @include device-md{
            row-gap: 40px;
        }
        hr {
            border: 0;
            margin: 0;
            margin-block: 12px;
            border: 2px solid $green;
        }
    }
    &-summary {
        row-gap: 12px;
        @include main-grid;

        &__header {
            display: flex;
            gap: 12px;

            grid-column: 1/-1;

            @include device-md {
                flex-direction: column;
            }

            img {
                background-color: #f4f4f4;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                padding: 4px;
                object-fit: contain;
                display: block;
            }

            &__title {
                display: flex;
                align-items: center;
                gap: 4px;
                text-decoration: none;
                @include device-md {
                    pointer-events: none;
                    svg {
                        display: none;
                    }
                }
            }

            &__acc-summary {
                display: flex;
                gap: 12px;
                justify-content: space-between;

                .acc-chevron {
                    @include device-md('min') {
                        display: none;
                    }
                }
            }

            .text-link {
                @include device-md('min') {
                    display: none !important;
                }
            }

            p {
                color: $dark-gray;
            }
        }
    }
    &-panel {
        @include device-md {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 500ms;
        }
    }
    &-subcategories {
        align-items: center;
        border: 1px solid $medium-gray;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        text-decoration: none;

        svg {
            flex-shrink: 0;
        }

        &__label {
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &-details-box {
        align-items: start !important;
        row-gap: 8px;
        overflow: hidden;

        @include main-grid();
        @include main-grid-columns(
            (
                'xl': 4,
                'lg': 3,
                'md': 2,
                'sm': 1,
            )
        );
        @include device-md {
            row-gap: 16px;
        }
    }

    &-item {
        $item: &;
        grid-column: 1/-1;
        @include device-lg('min') {
            &:first-of-type {
                grid-column: 1/-1;
            }
            &:nth-of-type(odd):not(:first-of-type) {
                grid-column: 13/-1;
            }
            &:nth-of-type(even):not(:first-of-type) {
                grid-column: 1/12;
            }
        }

        @include device-md {
            .toggle-down.acc-chevron {
                display: flex;
            }
            .toggle-up.acc-chevron {
                display: none;
            }

            &.active {
                #{$root}-panel {
                    grid-template-rows: 1fr;
                }

                .toggle-down.acc-chevron {
                    display: none;
                }
                .toggle-up.acc-chevron {
                    display: flex;
                }
            }
        }

        &:not(:first-of-type) {
            #{$root}-details-box {
                @include main-grid-columns(
                    (
                        'xl': 2,
                        'lg': 2,
                        'md': 2,
                        'sm': 1,
                    )
                );
            }
        }
    }
}

.filtered-equipments {
    $root: &;
    &-list {
        display: flex;
        flex-direction: column;
        gap: 80px;
        @include device-md {
            gap: 60px;
        }
    }
    &-summary {
        row-gap: 12px;
        @include main-grid;
        &__header {
            grid-column: 1/12;
            display: flex;
            gap: 12px;

            @include device-lg {
                grid-column: 1/-1;
            }

            @include device-md {
                flex-direction: column;
            }

            img {
                background-color: #f4f4f4;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                padding: 4px;
                object-fit: contain;
                display: block;
            }

            &__title {
                display: flex;
                align-items: center;
                gap: 4px;
                text-decoration: none;
                @include device-md {
                    pointer-events: none;
                    svg {
                        display: none;
                    }
                }
            }

            &__acc-summary {
                display: flex;
                gap: 12px;
                justify-content: space-between;

                .acc-chevron {
                    @include device-md('min') {
                        display: none;
                    }
                }
            }

            .text-link {
                @include device-md('min') {
                    display: none !important;
                }
            }

            p {
                color: $dark-gray;
            }
        }

        &__property {
            &:first-of-type {
                grid-column: 13 / 18;

                @include device-lg {
                    grid-column: 1/6;
                }

                @include device-md {
                    grid-column: 1/4;
                }
            }
            &:last-of-type {
                grid-column: 19 / -1;

                @include device-lg {
                    grid-column: 7 / -1;
                }

                @include device-md {
                    grid-column: 5/-1;
                }
            }
            @include device-md {
                display: none;
            }
        }
        .--select {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
    &-details {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &-list {
            display: flex;
            flex-direction: column;
            gap: 48px;
            overflow: hidden;
        }
        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 18px;
            text-decoration: none;
            padding-block-end: 12px;
            padding-inline: 12px;
            border-bottom: 1px solid $medium-gray;

            &--main {
                font-weight: 700;
                line-height: 19px;
                background-color: $light-gray;
                border-radius: 6px;
                padding-block-start: 12px;

                border-width: 0;
            }
        }
    }
    &-panel {
        @include device-md {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 500ms;
        }
        &__content {
            overflow: hidden;
        }
    }
    &-item {
        @include device-md('min') {
            #{$root}-panel {
                margin-block-start: 32px;
            }
        }
        @include device-md {
            .toggle-down.acc-chevron {
                display: flex;
            }
            .toggle-up.acc-chevron {
                display: none;
            }

            &.active {
                #{$root}-panel {
                    grid-template-rows: 1fr;
                    margin-block-start: 32px;
                }
                #{$root}-summary__property {
                    @include device-md {
                        display: block;
                    }
                }

                .toggle-down.acc-chevron {
                    display: none;
                }
                .toggle-up.acc-chevron {
                    display: flex;
                }
            }
        }
    }
}
