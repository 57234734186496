.card-download {
    width: 350px;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    background-color: $white;
    border-radius: 12px;
    border: 1.5px solid transparent;
    position: relative;
    overflow: hidden;
    transition: all $timming ease-in-out;

    &__bar {
        height: 100%;
        width: 18px;
        background-color: $green;
        position: absolute;
        top: 0;
        left: -11px;
    }

    &__text {
        margin-left: 4px;
        width: 246px;
    }

    &__label {
        @include font-size(12, 16.37, 300);
        margin-bottom: 4px;
    }

    &:hover {
        border: 1.5px solid $green;

        .card-download__text {
            color: $black;
        }
    }

    @include device-md {
        width: 100%;
    }
}
