.product-info {
    display: flex;

    &__image {
        background: $white;
        border: 2px solid $medium-gray;
        border-radius: 12px;
        height: max-content;

        img {
            display: block;
            margin-right: auto;
            margin-left: auto;
            object-fit: contain;
            width: 160px;
            height: 160px;
        }
    }

    &__middle {
        margin-left: 30px;

        .h5 {
            letter-spacing: 0;
            color: $green;
        }

        .--text-s {
            &.category {
                font-weight: 700;
                padding-bottom: 24px;
                border-bottom: 1px solid $medium-gray;
                margin-bottom: 24px;
            }

            &.description {
                letter-spacing: -0.02em;
                margin-bottom: 40px;

                p {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__characteristics {
        display: flex;
        flex-direction: column;
        margin-left: 56px;
        padding-top: 108px;

        div {
            width: 229px;
            margin-bottom: 20px;
        }

        .name {
            @include font-size(18, 25, 700);
            margin-bottom: 4px;
        }

        .--text-s {
            letter-spacing: -0.02em;
        }
    }

    @include device-lg {
        flex-direction: column;

        &__image {
            margin-bottom: 30px;
            width: 160px;
            height: 160px;
        }

        &__middle {
            margin-left: 0px;
        }

        &__characteristics {
            margin-top: 0px;
            padding-top: 0;
            margin-left: 0;
            margin-bottom: 16px;

            div {
                width: 168px;
            }
        }
    }
}