.form-webinars {
    background-color: $light-gray;
    padding-top: 80px;
    padding-bottom: 80px;

    &__container {
        border-radius: 10px;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        column-gap: 30px;
        overflow: hidden;

        .form {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 40px;

            .h4 {
                margin-bottom: 16px;
            }

            .--text-s {
                margin-bottom: 48px;
            }

            .form__field {
                margin-bottom: 32px;
                min-width: 440px;
            }

            .btn {
                margin-top: 40px;
                min-width: 255px;
            }
        }

        img {
            width: 50%;
            min-height: 100%;
            object-fit: cover;
        }
    }

    &__success-msg {
        display: none;
        align-items: center;
        gap: 8px;
        color: $green;
        font-weight: 700;
        svg {
            stroke: $green;
        }
    }
    

    @include rwd(900) {
        padding-top: 40px;
        padding-bottom: 40px;

        &__container {
            flex-direction: column-reverse;

            .form {
                width: 100%;
                padding: 24px 16px;

                .h4 {
                    margin-bottom: 8px;
                }

                .--text-s {
                    margin-bottom: 32px;
                }

                .form__field {
                    margin-bottom: 15px;
                    width: 100%;
                }

                .btn {
                    width: 100%;
                }
            }

            img {
                width: 100%;
                height: 205px;
            }
        }
    }
}