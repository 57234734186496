.schedule {
    .h4 {
        margin-bottom: 42.61px;
        font-weight: 400;
    }

    &__title {
        color: $green;
        padding-bottom: 20px;
        border-bottom: 1px solid $green;
        @include font-size(24, 32.7, 400, -2)
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    &__item {
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: 252.74px;

        .title {
            @include font-size(18, 24.6, 400, 0);
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid #E9E9E9;
        }

        .element {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 32px;
            margin-bottom: 12px;

            p {
                @include font-size(16, 22, 400, -2);
                color: $dark-gray;
            }
        }
    }

    @include device-lg {
        &__container {
            grid-template-columns: repeat(2, 1fr);
        }

        &__item {
            margin-top: 30px;
            margin-bottom: 10px;
        }
    }

    @include rwd(650) {
        &__container {
            grid-template-columns: repeat(1, 1fr);
        }

        &__item {
            width: 100%;
            max-width: none;
        }
    }
}