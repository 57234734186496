.data-sheet {
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 43px;

        .h4 {
            color: $green;
        }

        .form__field {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .toggle-slider {
                background-color: $green !important;
            }
        }
    }

    &__features {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 16px;

        @include rwd(800){
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include rwd(650) {
            grid-template-columns: 1fr 1fr;
        }

        @include device-sm {
            grid-template-columns: 1fr;
        }

        li {
            margin-bottom: 40px;

            .title {
                @include font-size(18, 22.5, 700, 0);
                margin-bottom: 4px;
            }

            .text {
                @include font-size(16, 20, 400, 0);
            }
        }
    }

    @include device-md {
        &__title {
            flex-direction: column;

            .h4 {
                margin-bottom: 24px;
            }

            .form__field {
                gap: 8px;
                flex-direction: row;

                .form__label {
                    width: 50%;
                }
            }
        }

        &__features {
            li {
                margin-bottom: 32px;
                margin-right: 5px;

                .title {
                    margin-bottom: 2px;
                }
            }
        }
    }
}
