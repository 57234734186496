.equipment {
    &__container {
        background-color: $white;
        z-index: 100;

        &__content {
            display: flex;
        }
    }

    &__left {
        padding-right: 44px;
        display: flex;
        flex-direction: column;
        border-right: 0.782922px solid #e7e7e7;
        overflow: scroll;
        height: 100%;
        @include custom-scrollbars;

        &__title {
            @include font-size(16, 14, 700, 0.78);
            margin-bottom: 20px;
            padding-left: 12px;
        }

        .equipment__item {
            height: auto;
            margin-bottom: 13px;
            padding: 9.5px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border-radius: 10px;
            border: 1px solid transparent;
            cursor: pointer;

            img {
                border-radius: 50%;
                height: 50px;
                width: 50px;
                object-fit: contain;
                background-color: #f4f4f4;
            }

            span {
                margin-left: 12px;
                display: flex;
                flex-direction: column;
                max-width: 230px;

                .title {
                    @include font-size(14, 18, 700, 0.23);
                }

                .description {
                    @include font-size(11, 14, 600, 0);
                    color: $dark-gray;
                }
            }

            &:last-of-type {
                margin-bottom: 21px;
            }

            &:hover {
                border: 1px solid $green;
            }

            &.--active {
                background-color: $green;
                span {
                    p {
                        color: $white;
                    }
                }
            }

            &.--active {
                pointer-events: none;
            }
        }
    }

    &__center {
        max-width: 747px;
        width: 100%;
        padding-right: 50px;
        padding-left: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;

        div {
            display: none;
            opacity: 0;
            transition: opacity $timming ease-in-out;

            .title {
                @include font-size(16, 14, 700, 0.78);
                color: $green;
                margin-bottom: 23.5px;
            }

            li {
                a {
                    text-decoration: none;
                    @include font-size(14, 24, 400, 0.3);
                    color: $dark-gray;
                    margin-bottom: 7px;
                    transition: all $timming ease-in-out;

                    &:hover {
                        text-decoration: underline;
                        color: $green;
                        cursor: pointer;
                    }
                }
            }

            .all {
                margin-top: 10px;
            }
        }
    }

    &__right {
        padding-left: 20px;
        border-left: 0.782922px solid #e7e7e7;
        opacity: 0;
        transition: opacity $timming ease-in-out;

        p {
            text-transform: uppercase;
            color: $green;
            @include font-size(10, 14, 700, 0.78);
            margin-bottom: 24px;
        }

        li {
            a {
                text-decoration: none;
                @include font-size(14, 24, 600, 0.13);
                color: $dark-gray;
            }
        }
    }

    &-mobile {
        display: none;
    }

    @include rwd(1360) {
        display: none;

        &-mobile {
            display: block;
        }
    }
}

// equipments-mobile:
.header__secondary-nav {
    position: fixed;
    z-index: 2;
    right: 0;
    top: 0;
    bottom: 0;
    min-width: 20rem;
    width: 100%;
    background-color: $white;
    visibility: hidden;
    transform: translateX(100%);
    transition: all 300ms ease-in-out;
    border-top: 3px solid $green;
    border-bottom: 1px solid $medium-gray;

    @include rwd(1360) {
        &.open {
            visibility: visible;
            transform: translateX(0);
            height: 100%;
            min-width: auto;
            transition: all $timming ease-in-out;
        }

        .header__logo {
            position: absolute;
            top: 0;
            left: 16px;
            height: 65px;
            display: flex;
            align-items: center;
        }

        .header__anchor {
            position: relative;
            display: flex;
            height: 45px;
            padding-left: 14px;
            padding-right: 14px;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            @include font-size(18, 18, 400);
            text-decoration: none;
            transition: color $timming ease-in-out;
            margin-top: 40px;
            margin-left: 16px;
            margin-right: 16px;
            margin-bottom: 20px;

            &::after {
                content: "";
                position: absolute;
                inset: auto 8px 8px 8px;
                width: 0%;
                height: 1px;
                border-bottom: 2px solid $green;
                transition: width $timming ease-in-out,
                    border-color $timming ease-in-out;
            }

            &:hover,
            &.active,
            &.--active {
                color: $green;

                &::after {
                    width: calc(100% - 28px);
                }
            }

            &.--black {
                background-color: $black;
                color: $white;
                transition: background-color $timming ease-in-out;

                &::after {
                    display: none;
                }

                &:hover {
                    background-color: $orange;
                }
            }
        }

        &__list {
            position: absolute;
            top: 58px;
            bottom: 0;
            right: 0;
            left: 0;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: $white;
            border-top: 1px solid $light-gray;

            ul {
                display: flex;
                flex-direction: column;
                row-gap: 20px;

                li {
                    padding: 20px 16px 0px 16px;
                    border-top: 1px solid $light-gray;

                    &:first-of-type {
                        border-top: none;
                    }

                    .li-text {
                        @include font-size(20, 24, 300, 0);
                        display: inline-block;
                        width: 100%;
                    }

                    a {
                        text-decoration: none;
                    }
                }
            }
        }

        .dropdown__item:last-of-type {
            border-bottom: 1px solid $light-gray;
            padding-bottom: 20px;
        }

        .dropdown__item {
            .panel {
                @include device-md {
                    padding-bottom: 0 !important;
                }
            }
        }

    }
}

.header__dropdown__container {
    .search {
        display: flex;
        color: $black;

        .header-search-bar__container-mobile {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .form__field {
                margin-right: 8px;

                &.--input {
                    width: 100% !important;
                }

                input::placeholder,
                svg {
                    color: $black !important;
                }
            }
        }
    }

    .accordion-dropdown-header {
        border: none;
        padding-top: 0;
        padding-bottom: 0;

        &.active {
            color: $green;
            padding-bottom: 20px;
            border-bottom: 1px solid $green;
        }
    }
    .accordion-dropdown-item__summary {
        padding-block: 0;
        border: 0;
    }
    .accordion-dropdown-item__details {
        padding-left: 0;
        padding-top: 0;

        ul {
            margin-top: 0;
            padding-top: 0;
            row-gap: 0;

            li {
                border: none;
                padding-left: 0;
                padding-right: 0;

                span {
                    max-width: none;
                }

                .title {
                    color: $green;
                }
            }
        }
    }

    .--icon-circle {
        max-width: 28px;
        min-width: 28px;
        max-height: 28px;
        min-height: 28px;
    }
}
 