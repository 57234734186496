.recommender {
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    padding-bottom: 30px;
    min-height: 700px;
    transition: height $timming ease-in-out;

    &__body {
        position: relative;
        flex-grow: 1;
    }

    .back-to-top {
        display: none;
    }

    &__form-success {
        width: 100%;
        min-height: 480px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #FAFAFA;
        text-align: center;
        padding: 0 20px;
        transition: opacity $timming ease-in-out;

        .handshake {
            display: inline-block;
            margin-bottom: 16px;
        }

        p {
            @include font-size(20, 24, 400);
            color: $dark-gray;
            max-width: 540px;
            text-align: center;
        }
    }

    .step-card {
        display: none;

        &.--active {
            display: block;
        }
    }

    .cta-container {
        display: flex;
        justify-content: end;
        gap: 1rem;
        margin-top: 2rem;
    }

    .step-2-container {
        display: grid;

        grid-template-columns: 1fr;
        column-gap: 30px;
        row-gap: 24px;

        @include device-lg("min") {
            grid-template-columns: 1fr 1fr;
        }
    }

    .back-button {
        margin-top: 40px;
    }

    &__summary {
        position: sticky;
        top: 113px;
        align-self: flex-start;

        @include rwd(1250) {
            top: 93px;
        }

        @include device-md {
            position: static;
        }

        &--wrapper {
            background-color: $white;
            border: 2px solid $medium-gray;
            border-radius: 8px;
            margin-bottom: 20px;
        }

        &.--hidden {
            display: none;
        }

        &__header,
        &__body {
            padding: 16px;
            border-bottom: 2px solid $medium-gray;
        }

        &__body {
            h6 {
                color: $dark-gray;
                @include font-size(14, 16, 600);
                margin-bottom: 16px;
            }

            ul {
                padding-left: 10px;
            }

            &:has(> ul:empty) {
                display: none;
            }
        }

        &__footer {
            padding: 16px;
            text-align: center;

            button {
                display: inline-flex;
                align-items: center;
                gap: .3rem;
                color: $green;
                @include font-size(14, 16, 600);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__empty {
            align-self: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            text-align: center;

            &.--hidden {
                display: none;
            }
        }

        .recommender__btn--next {
            width: 100%;
        }
    }

    .step-3-container {
        display: grid;
        grid-template-columns: auto 440px;
        gap: 30px;

        .right {
            position: relative;
            display: grid;
            padding: 40px;
            background-color: #F1F1F1;
        }

        @include device-lg {
            grid-template-columns: 1fr;
        }

        @include device-md {
            .right {
                padding: 20px;
            }
        }
    }

    .step-4-container {
        display: grid;
        grid-template-columns: auto 440px;
        gap: 30px;

        @include device-lg {
            grid-template-columns: 1fr;
        }

        .left {
            container-type: inline-size;
        }

        .right {
            position: relative;
            display: grid;
            padding: 40px;
            background-color: #F1F1F1;

            @include device-md {
                padding: 20px;
            }
        }
    }

    .footer-recommender {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: .5rem;
    }

    &__filter {
        &__input-container {
            display: grid;
            align-items: center;
            grid-template-columns: 20px 1fr 20px;
            padding: 0px 12px;
            height: 45px;
            background: $white;
            border: 1px solid $medium-gray;
            border-radius: 8px;
            transition: all 500ms ease-in-out;
            overflow: hidden;
            cursor: pointer;
            &:hover {
                > svg {
                    stroke: $green;
                }
            }
        }

        &__input-search {
            width: 20px;
            height: 20px;
        }

        &__input {
            border: 0;
            font-size: 16px;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.375;
            letter-spacing: -0.02em;
            color: #6d6d6d;
            transition: opacity $timming ease-in-out;
            height: 45px;
            margin-inline: 8px;

            &:focus {
                outline: none;
                color: $black;
            }
        }

        &__clean-value-container {
            overflow: hidden;
            height: 20px
        }

        &__clean-value {
            cursor: pointer;
            width: 20px;
            height: 20px;
            stroke: #999999;
            display: none;
            &.--show {
                display: unset;
            }
        }
    }

    &__chooser {
        &__buttons {
            display: flex;
            flex-flow: row wrap;
            gap: 1rem;
            padding-bottom: 16px;
            border-bottom: 1px solid $medium-gray;

            @include device-sm {
                gap: .5rem;
            }
        }

        &__button {
            span {
                display: block;
                padding: 7px 17px;
                background-color: $medium-gray;
                color: $black;
                border-radius: 20px;
                cursor: pointer;
                transition: all $timming ease-in-out;

                @include device-sm {
                    padding: 7px 12px;
                }
            }

            [type="radio"] {
                opacity: 0.01;
                z-index: 100;
                position: absolute;

                &:checked + span {
                    background-color: $black;
                    color: $white;
                }
            }

            &.--hide {
                display: none;
            }
        }

        &__tabs {
            container-type: inline-size;
            display: block;
            height: 450px;
            margin-top: 30px;
            overflow: hidden scroll;
            @include custom-scrollbars;

            @include device-lg {
                height: auto;
            }
        }

        &__tab {
            padding-right: 24px;
            opacity: 0;
            transition: opacity $timming ease-in-out;

            &.--active {
                opacity: 1;
            }

            &.--hide {
                display: none;
            }

            header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;

                .checkbox-container {
                    margin-left: auto;
                }
            }

            .cards {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;

                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                @container (max-width: 630px) {
                    grid-template-columns: 1fr;
                }
            }

            @include device-md {
                padding-right: 0;
            }

            @include device-sm {
                header p {
                    display: none;
                }
            }
        }
    }

    .checkbox-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;

        cursor: pointer;

        &:nth-of-type(2) {
            border-left: 1px solid $medium-gray;
        }

        input[type="checkbox"] {
            appearance: none;
            background-color: $white;
            margin: 0;
            color: currentColor;
            border: 1px solid $black;
            transform: translateY(-0.075em);
            display: inline-grid;
            place-content: center;
            vertical-align: middle;
            transition: all $timming ease-in-out;
            width: 16px;
            height: 16px;
            border-radius: 4px;

            &.--big {
                width: 16px;
                height: 16px;
                border-radius: 4px;

                &::before {
                    width: 0.8em;
                    height: 0.8em;
                }
            }

            &.--small {
                width: 14px;
                height: 14px;
                border-radius: 3.5px;

                &::before {
                    width: 0.6em;
                    height: 0.6em;
                }
            }

            &::before {
                content: "";
                transform: scale(0);
                box-shadow: inset 1em 1em $green;
                background-color: CanvasText;
                clip-path: polygon(18% 41%,
                        5% 55%,
                        37% 84%,
                        100% 20%,
                        87% 7%,
                        37% 58%);
                transition: all $timming ease-in-out;
                width: 0.8em;
                height: 0.8em;
            }

            &:hover {
                border: 1px solid $green;
                background-color: $light-green-50;
                cursor: pointer;
            }

            &:checked {
                background-color: $light-green-50;
                border-color: $green;

                &::before {
                    transform: scale(1);
                }
            }
        }
    }

    &__form {
        &-section {
            margin-bottom: 16px;
            @include font-size(18, 24, 400);
        }

        &-columns {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 1rem;
            row-gap: 32px;

            @container (max-width: 500px) {
                grid-template-columns: 1fr;
            }
        }
    }

    .form__field,
    .form__field.--textarea,
    .form__field.--textarea textarea,
    .recommender__submit {
        width: 100%;
    }
}
