.cards-quotation {
    display: flex;
    justify-content: space-between;

    &__text {
        margin-right: 29px;

        .title {
            
            @include font-size(35, 42, 400, 0);
            max-width: 445px;
            margin-bottom: 10px;
        }

        .--text-s {
            max-width: 445px;
            margin-bottom: 50px;
        }

        .btn {
            width: max-content;
            min-width: 254px;
        }
    }

    &__cards {
        max-width: 635px;
        width: 60%;

        .card-dash {
            width: 100%;
            margin-bottom: 40px;
        }
    }

    @include device-lg {
        position: relative;
        flex-direction: column;

        &__text {
            margin-right: 0;

            .title {
                margin-bottom: 16px;

                p {
                    @include font-size(30, 36, 400, 0);
                }
            }

            .--text-s {
                margin-bottom: 40px;
            }
        }

        &__cards {
            width: 100%;
            margin-bottom: 110px;
    
            .card-dash {
                width: 100%;
                margin-bottom: 16px;
            }
        }

        .btn {
            position: absolute;
            width: 260px;
            left: 50%;
            transform: translateX(-50%);
            bottom: 67px;
        }
    }

    @include device-sm {
        .btn {
            width: calc(100% - 32px);
        }
    }
}