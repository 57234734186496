@mixin main-grid($height: auto, $rows: auto) {
    display: grid;
    height: $height;
    
    @if $rows != auto {
        grid-template-rows: repeat($rows, 1fr);
    }
    
    grid-template-columns: repeat(3, 1fr 16px) 1fr;
    row-gap: 16px;

    @include device-md("min") {
        grid-template-columns: repeat(11, 1fr 20px) 1fr;
        row-gap: 20px;
    }

    @include device-xl("min") {
        grid-template-columns: repeat(11, 1fr 40px) 1fr;
        row-gap: 40px;
    }
}