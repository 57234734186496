.illumination-area {
    &__images {
        width: 950px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        margin-bottom: 79px;

        .first {
            width: 505px;
            height: 203.79px;
            object-fit: contain;
            margin-right: 139px;
        }

        .second {
            width: 306px;
            height: 281px;
            object-fit: contain;
        }
    }

    @include device-lg {
        &__images {
            flex-direction: column;
            width: auto;

            .first {
                margin-right: 0;
                margin-bottom: 40px;
                width: 100%;
            }

            .second {
                width: 100%;
            }
        }
    }
}