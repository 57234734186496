.text-img-two-columns {
    background-color: $light-gray-25;
    padding-top: 77px;
    padding-bottom: 106px;

    .wrapper {
        gap: 2rem;
    }

    &.left {
        .wrapper {
            flex-direction: row-reverse;
        }
    }

    &.right {
        .wrapper {
            flex-direction: row;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .h4 {
            max-width: 506.3px;
            margin-right: 31px;
        }

        img {
            width: 100%;
            max-width: 574px;
            height: 100%;
            max-height: 305px;
            object-fit: contain;
        }
    }

    @include device-lg {
        &.left,
        &.right {
            padding-bottom: 40px;

            .wrapper {
                flex-direction: column-reverse;

                img {
                    width: 100%;
                }
            }
        }
    }
}
