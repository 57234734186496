.title-subtitle {
    max-width: 920px;
    margin: 0 auto;
    text-align: center;

    @include device-sm {
        text-align: start;
    }

    .h2-light {
        margin-bottom: 2.5rem;
        @include device-md {
            margin-bottom: 1.5rem;
            @include font-size(32, 40, 400)
        }
    }

    .--text-l {
        @include device-md {
            @include font-size(18, 24, 400)
        }
    }
}
