.contact-bullet {
    &__container {
        position: relative;

        @include main-grid();

        .right {
            grid-column: 1/8;
            
            .card {
                display: flex;
                gap: 1rem;
                align-items: flex-start;
                margin-top: 24px;

                img {
                    background-color: $green-light;
                    height: 2rem;
                    width: 2rem;
                    padding: 0.5rem;
                    border-radius: 100%;
                }

                .h4-regular {
                    @include font-size(26, 27.3, 400);
                    margin-bottom: 8px;
                }

                a {
                    text-decoration: none;
                    color: $green;
                    @include font-size(16, 22, 700, -0.32);
                    margin-top: 8px;
                }
            }

            @include device-md("min") {
                grid-column: 7/-1;
            }
            
            
            @include device-lg("min") {
                grid-column: 13/24;
                
                .card {
                    .h4-regular {
                        @include font-size(32, 40, 400, -2);
                    }
                }
            }
        }

        .left {
            grid-column: 1/8;

            .--text-body-l {
                @include font-size(16, 22, 400);
                margin-bottom: 4px;
            }

            .title {
                .h3 {
                    @include font-size(36, 36, 700);
                }
            }

            @include device-md("min") {
                grid-column: 1/6;
            }

            @include device-lg("min") {
                grid-column: 2/12;

                .--text-body-l {
                    @include font-size(20, 27.3, 400, -0.4);
                }

                .title {
                    .h3 {
                        @include font-size(40, 50, 700, -0.8);
                    }
                }
            }
        }
    }
}
