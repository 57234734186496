.bullet-highlighted {
    
    &__5-reasons {
        display: flex;
        align-items: baseline;
        padding-bottom: 14px;

        p {
            @include font-size(42, 57.29, 400, 0);
            margin-left: 10px;
    
            &.number {
                text-shadow: -3px 3px 0 $green,
                3px 3px 0 $green,
                3px -3px 0 $green,
                -3px -3px 0 $green;
                color: $white;
                @include font-size(132, 180, 400, 0);
            }
        }

        strong {
            color: $green;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1110px;
        margin-right: auto;
        margin-left: auto;
        // padding-top: 26px;
    }

    &__content {
        max-width: 255px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 45px;

        .--icon-circle {
            max-width: 72px;
            max-height: 72px;
            min-width: 72px;
            min-height: 72px;
            margin-bottom: 16px;
        }
    }

    &__separator {
        width: 1.5px;
        background-color: $medium-gray;
        margin-right: 14px;
        margin-left: 14px;

        &:last-of-type {
            display: none;
        }
    }

    &__text {
        p {
            font-weight: 700;
            text-align: center;
        }
    }

    &__bottom-text {
        margin-top: 40px;
        max-width: 992px;
    }

    &__mobile {
        display: none;
    }

    .green-circle {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        background-color: $green;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;

        p {
            color: $white;
            text-align: center;
            @include font-size(20, 27.28, 700, 0)
        }
    }

    @include device-lg {
        display: none;

        &__mobile {
            display: block;
    
            &__content {
                max-width: 210px;
                width: 100%;
                margin-bottom: 40px;
                margin-left: auto;
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-right: auto;
    
                .--icon-circle {
                    max-width: 72px;
                    max-height: 72px;
                    min-width: 72px;
                    min-height: 72px;
                    margin-bottom: 16px;
                }
            }
    
            &__text {
                margin-left: 0px;
                p {
                    text-align: center;
                }
            }
    
            &__separator {
                display: none;
            }
    
            &__bottom-text {
                &.--text-body-l {
                    @include font-size(18, 25);
                    margin-bottom: 8px;
                }
            }

            .swiper {
                .swiper-slide {
                    margin-right: 0;

                    .card-features {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .swiper-pagination-bullet-active {
                    background-color: $green;
                }
            }
        }

        &__5-reasons {
            p {
                @include font-size(24, 32.74, 400, 0);
                
                &.number {
                    text-shadow: -2px 2px 0 $green,
                    2px 2px 0 $green,
                    2px -2px 0 $green,
                    -2px -2px 0 $green;
                    color: $white;
                    @include font-size(70, 95.48, 400, 0);
                }
            }
        }
    }
}
