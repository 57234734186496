.cta-consult-an-expert {
    background-color: $light-gray-25;
    padding-top: 97px;
    padding-bottom: 97px;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .h4 {
            margin-bottom: 8px;
        }

        p {
            max-width: 42rem;
            color: $black;
            margin-bottom: 32px;

            @include font-size(18, 28, 300);
        }
    }

    @include device-md {
        padding-top: 85px;
        padding-bottom: 85px;
    }
}
