.card-subcategory {
    // width: 255px;
    background-color: $white;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    border: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all $timming ease-in-out;

    &__image {
        width: 100%;
        // height: 150px;
        aspect-ratio: 1.7;
        object-fit: cover;
    }

    &__text {
        color: $green;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px 15px 16px;
        margin-top: auto;
        margin-bottom: auto;
        
        .--text-s {
            width: 195px;
            letter-spacing: -0.02em;
        }
    }

    &:hover {
        border-color: $green;
    }
}