.bullet-title {
    
    &__container {
        display: grid;
        width: 100%;
        // padding-top: 26px;
        gap: 30px;
    }

    &.three {
        .bullet-title__container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    
    &.four {
        .bullet-title__container {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__content {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        // @include device-lg("min"){
        //     justify-content: center
        // }
    }

    &__text {
        margin-left: 24px;
        max-width: 190px;
    }

    .green-circle {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        background-color: $green;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;

        p {
            color: $white;
            text-align: center;
            @include font-size(20, 27.28, 700, 0)
        }
    }
    
    @include device-lg {
        &.three {
            .bullet-title__container {
                grid-template-columns: 1fr;
            }
        }
        
        &.four {
            .bullet-title__container {
                grid-template-columns: 1fr;
            }
        }

        &__content {
            width: 100%;
            margin-bottom: 0px;
        }

        &__text {
            max-width: 100%;
        }
    }
}