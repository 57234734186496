.client-form {
    $root: &;
    &-section {
        background-color: $light-gray-25;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &-box {
        padding: 24px 32px;
        background-color: $white;
        box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.10);
        border-radius: 16px;
    }

    &-grid {
        padding-top: 36px;
        display: grid;
        grid-template-columns: 0.8fr 0.8fr 1.5fr;
        gap: 30px;
        align-items: start;
        @include rwd(1260) {
            grid-template-columns: 1fr 1fr;
        }
        @include device-sm {
            grid-template-columns: 1fr;
        }

        .form__field {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 7px;
            & > .checkLabelHead, & > .textareaLabel {
                @include font-size(15, 21, 300);
                color: $green;
                border-bottom: 1px solid #e5e5e5;
                width: 100%;
                margin-bottom: 8px;
                margin-top: 37px;

                &:first-of-type {
                    margin-top: 0;
                }
            }
            & > .textareaLabel {
                border-bottom: 0;
            }
            &:first-of-type {
                margin-bottom: 40px;
            }
        }

        #{$root}-message-col{
            @include rwd(1260) {
                grid-column: 1/-1;
            }
            .--textarea {
                width: 100% !important;

                textarea {
                    max-width: 100%;
                    width: 100% !important;
                    height: 100% !important;
                    background: #F6FEF9 !important;
                    border-radius: 10px !important;
                    border: none !important;
                    &:focus {
                        outline: 1px solid $green !important;
                    }
                }

                .form__control {
                    width: 100% !important;
                    height: 149px;
                    resize: none;
                }
            }
        }
    }

    &-control {
        .btn[type="submit"] {
            background: #F7931D;
            border-radius: 30px;
            max-width: 100%;
            width: 100%;
            height: 50px;
            color: $white;
            display: flex;
            width: 100%;
            justify-content: flex-end;

            @include device-sm("min") {
                max-width: 240px !important;
            }

            &:hover {
                background: $green;
                transition: background 0.2s ease-out;
                border-color: transparent;
            }
        }
    }
}
