.form-training {
  background-color: #F1F1F1;
  padding: 80px 16px;
  .wrapper {
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
  }
  .--text-l {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 40px;
  }
  form {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 24px;
    row-gap: 24px;
    @include device-md("min"){
      grid-template-columns: 1fr 1fr;
    }
    .form-training__left, .form-training__right {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .form__field {
        width: 100%;
        textarea {
          width: 100%;
          height: 110px;
        }
      } 
    }
    .control {
      justify-self: end;
      padding-top: 30px;
      grid-column: 1 / -1;
    }
  }
}