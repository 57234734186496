.card-product-checkout {
    & > .title {
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    &__container {
        display: flex;
        flex-direction: column;

        .items {
            overflow: auto;
            max-height: 50vh; // Fallback
            max-height: 50svh;
        }
        
    }

    &__info-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 18px;
        gap: 8px;

        @include device-sm{
            flex-direction: column;
            .--remove-all-sp {
                align-self: flex-end;
            }
        }

        .title {
            @include font-size(16, 22, 400);
            color: $black;
            letter-spacing: -0.32px;
        }

        .--remove-all-sp {
            color: $dark-gray;
            @include font-size(12, 16, 700);
        }
    }

    .panel {
        display: none;
        flex-direction: column;
        overflow: hidden;
        padding-top: 70px;

        .items {
            max-height: 43vh;
            overflow: auto;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            padding-bottom: 20px;
            margin-bottom: 1rem;
            border-bottom: 1px solid #e7e7e7;

            @include rwd(600) {
                flex-direction: column;
                gap: 20px;

                .btn-remove {
                    display: none
                }
            }

            .quantity {
                display: flex;
                height: 48px;
                border-radius: 8px;
                background-color: $light-green-50;
                padding: 24px 14px;
                align-items: center;

                span {
                    margin: 14.5px;
                    @include font-size(14, 19.1, 700);
                }

                svg {
                    stroke: $green;
                    pointer-events: none;
                    vertical-align: middle;
                }

            }
            .btn-remove {
                margin-right: 12px;
            }
        }

        &__quantity {
            .btn-remove-link {
                display: none;
                &.--remove-all-sp {
                    display: inline-block;
                    @include font-size(14, 19, 700);
                    color: $dark-gray;
                }
            }
            @include rwd(600) {
                .btn-remove-link {
                    display: inline-block;
                    @include font-size(14, 19, 700);
                    color: $dark-gray;
                    &.--remove-all-sp {
                        @include font-size(14, 19, 700);
                        padding: 16px 24px;
                        min-height: 48px;
                        border-radius: 8px;
                        border: 1px solid $light-gray;
                    }
                }
            }
            @include rwd(600) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
        }

        &__content {
            display: flex;
            align-items: center;
            // width: max-content;
            width: 100%;
            
            svg {
                stroke: $green;
                pointer-events: none;
                vertical-align: middle;
                height: 19px;
                width: 19px;
            }
            
        }

        &__image {
            padding: 5.5px;
            background-color: $white;
            border: 1px solid #e7e7e7;
            border-radius: 4px;
            height: 65px;
            width: 65px;

            img {
                width: 54px;
                height: 54px;
                object-fit: contain;
            }
        }

        &__text {
            margin-left: 20px;
            max-width: 858px;

            .title {
                @include font-size(16, 24.55, 700);
            }

            .--text-xs {
                line-height: 19.1px;
                color: $dark-gray;
                margin-top: 4px;
            }
        }

        &__footer-buttons {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
    }


    @include rwd(600) {
        &__container {
            .items {
                max-height: 65vh; // Fallback
                max-height: 65svh;
            }
        }
    }
    .product_quantity {
        width: 30px;
        border: 0;
        background-color: transparent;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        color: $black;
        pointer-events: none;
    }
}
