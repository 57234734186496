.storybook {
    &__categories {
        background-color: $green;
        padding-top: 40px;
        padding-bottom: 40px;

        .h2 {
            color: $white;
            margin-bottom: 40px;
        }

        .list {
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 30px;

            .h5.pending {
                color: $black;

                &:hover {
                    color: $black;
                }
            }

            .h5:hover {
                color: $green-light;
            }
        }

        .note {
            border: 1px solid $white;
            border-radius: 10px;
            color: $white;
            margin: 30px auto;;
            padding: 20px 40px;
            text-align: center;
            max-width: max-content;

            span {
                text-decoration: underline;
            }
        }
    }

    &.h2.wrapper {
        padding-top: 100px;
        padding-bottom: 20px;
    }

    &.h4.wrapper {
        padding-top: 80px;
        padding-bottom: 20px;
        color: $green;

        svg {
            &.fill {
                fill: $green;
            }
        }
    }

    &__up {
        position: fixed;
        bottom: 120px;
        right: 30px;
        z-index: 1000;

        &.--icon-circle {
            background-color: $green;
            color: $light-green-50;
            min-width: 60px;
            min-height: 60px;
            max-width: 60px;
            max-height: 60px;
        }
    }

    &__grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    &__main-grid {
        @include main-grid(400px);
        
        span {
            height: 100%;
            border: 1px dashed red;

            &:nth-child(1) {
                grid-column: 1/span 1;
            }
            &:nth-child(2) {
                grid-column: 3/span 1;
            }
            &:nth-child(3) {
                grid-column: 5/span 1;
            }
            &:nth-child(4) {
                grid-column: 7/span 1;
            }
            &:nth-child(5) {
                grid-column: 9/span 1;
            }
            &:nth-child(6) {
                grid-column: 11/span 1;
            }
            &:nth-child(7) {
                grid-column: 13/span 1;
            }
            &:nth-child(8) {
                grid-column: 15/span 1;
            }
            &:nth-child(9) {
                grid-column: 17/span 1;
            }
            &:nth-child(10) {
                grid-column: 19/span 1;
            }
            &:nth-child(11) {
                grid-column: 21/span 1;
            }
            &:nth-child(12) {
                grid-column: 23/span 1;
            }
        }

        @include device-lg {
            @include main-grid(400px);

            span:nth-child(n+7) {
                display: none;
            }
        }

        @include device-md {
            @include main-grid(200px);

            span:nth-child(n+5) {
                display: none;
            }
        }
    }
}