.card-error-ok {
    position: relative;
    max-width: 21.55rem;
    padding-top: 4rem;
    padding-bottom: 2.5rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    @include box-with-border-and-shadow();

    @include device-md("min") {
        max-width: 27rem;
    }

    &__icon {
        position: absolute;

        top: -42px;
        left: 50%;

        transform: translate(-50%, 0%);
    }

    &__title {
        margin-bottom: 1.5rem;
    }

    &.--error-type {
        p {
            strong {
                color: $danger;
                text-decoration: underline;
            }
        }
    }

    &.--ok-type {
        p {
            strong {
                color: $success;
                text-decoration: underline;
            }
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        margin-top: 1rem;
    }

    li {
        display: flex;
        gap: 0.5rem;
    }
}
