.form-survey {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .form-survey__card:has(.--has-error){
        border: 1px solid $danger;
        .is-danger{
            border: 1px solid $danger;
        }
    }
    &__card {
        width: 100%;
        background-color: $white;
        border-radius: 10px;
        border: 1px solid #E7E7E7;
        box-shadow: 0px 2px 8px -2px rgba(0,0,0,0.15);
        -webkit-box-shadow: 0px 2px 8px -2px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 2px 8px -2px rgba(0,0,0,0.15);
        padding: 32px 40px;
        margin: 12px 0;
        &-question {
            @include font-size(18, 24, 600);
            margin-bottom: 32px;
        }
        &-option {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            input {
                margin: 0;
            }
            label {
                @include font-size(16, 22, 400, -2);
                margin-left: 8px;
            }
            &.--column {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                gap: 8px;
            }
        }
        &-subquestions {
            padding: 20px;
            &:nth-child(odd){
                background-color: $light-gray;
            }
            &.--no-bg{
                background-color: transparent;
                padding: 20px 0;
            }
            &.--no-padding{
                padding: 0;
                .form-survey__card-options-container{
                    padding: 0;
                }
            }
            .form-survey__card-options-container{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px;
                .form-survey__card-option{
                    display: flex;
                    flex-direction: column-reverse;
                    row-gap: 8px;
                    margin-bottom: 0;
                    label {
                        margin-left: 0;
                    }
                }
            }
            .form-survey__card-question{
                @include font-size(16, 22, 600, -2);
            }
        }
        &:hover{
            border-color: $green;
        }
        &.--completed{
            border-color: $green;
            border-left: 10px solid $green;
            padding-left: 31px;
        }
    }

    .form__field{
        &.--has-error{
            .form__error {
                display: block;
                color: $danger;
                font-weight: 700;
            }
        }
    }

    textarea {
        resize: none;
        width: 100%;
        padding: 12px 8px;
        @include font-size(16, 22, 400, -2);
        border: 1px solid $medium-gray;
        border-radius: 8px;
        overflow: hidden;
        &:active, &:focus, &:focus-visible {
            border-color: $green;
            outline: $green;
        }
    }
    .btn {
        margin-top: 40px;
        margin-bottom: 50px;
    }
}

@include device-sm{
    .form-survey__card {
        margin: 6px 0;
        padding: 24px;
        &-question {
            margin-bottom: 24px;
        }
        &-options-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 16px;
        }
        &-option {
            flex-direction: row;
            column-gap: 4px;
        }
        &-subquestions{
            .form-survey__card-options-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 20px;
                padding: 0;
                .form-survey__card-option{
                    flex-direction: row;
                    column-gap: 4px;
                }
            }
        }
        &.--scale {
            .form-survey__card-options-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 20px;
                padding: 0;
            }
            .form-survey__card-option {
                flex-direction: row;
                column-gap: 4px;
            }
            .form-survey__card-subquestions{
                .form-survey__card-options-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    row-gap: 20px;
                    padding: 0;
                }
            }
        }
        &.--completed{
            border-color: $green;
            border-left: 10px solid $green;
            padding-left: 15px;
        }
    }
}