.locations {
    .h4 {
        font-weight: 400;
        margin-bottom: 31px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 16px;
        column-gap: 43px;

        p {
            display: flex;
            align-items: center;
            line-height: 48px;

            svg {
                color: $green;
                margin-right: 5px;
            }
        }
    }

    @include device-md {
        .h4 {
            @include font-size(20, 25.3, 400);
        }

        &__container {
            column-gap: 22px;
            row-gap: 8px;
            
            p {
                @include font-size(14, 48, 300);
            }
        }

    }
}