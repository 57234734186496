body.--fixed-breadcrump-active {
    #main-breadcrumb {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 7;
        background: $white;
        border-top: 3px solid $green;
        border-bottom: 1px solid $medium-gray;

        .breadcrumb-nav {
            &__cta {
                display: inline-block !important;
            }
            &.--ellipsis {
                .breadcrumb-nav__list {
                    li {
                        a, p {
                            @include rwd(900) {
                                display: block;
                                max-width: 10ch;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
        @include device-md {
            display: block;
        }
    }

    .main-header {
        z-index: -1;
        & > .header {
            visibility: hidden;
            opacity: 0;
        }
    }
}

#main-breadcrumb {
    .breadcrumb-nav {
        min-height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        &__cta {
            display: none !important;
        }

        &__help-copy {
            display: none;
        }

        &__list {
            display: flex;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                padding: 4px;

                &:hover {
                    background-color: #f1f1f1;
                    border-radius: 4px;

                    a, p {
                        color: $black;
                    }
                }
                a, p {
                    @include font-size(12, 16.37, 700);
                    text-decoration: none;
                    display: flex;
                    padding: 0 2px;

                    svg {
                        margin-right: 8px;
                    }
                }


                &:last-of-type {
                    pointer-events: none;

                    a, p {
                        color: $dark-gray;

                        svg {
                            display: none;
                        }
                    }
                }
            }
        }
        &.--ellipsis {
            .breadcrumb-nav__list {
                li {
                    a, p {
                        @include rwd(900) {
                            display: block;
                            max-width: 15ch;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
    @include device-md {
        display: none;
        .breadcrumb-nav {
            &__help-copy {
                display: inline-block;
                font-weight: 700;
            }
            &__list {
                display: none;
            }
        }
    }
}
