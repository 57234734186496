.quoter {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $white;
    border-top: 2px solid $green;
    z-index: 10;

    #sp-cart-counter {
        margin-left: 12px;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        color: $white;
        background-color: $green;
        font-weight: 700;
        @include font-size(16)
    }

    &__item {
        .active,
        .accordion:hover {
            cursor: pointer;
        }

        .accordion {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-top: 21.5px;
            padding-bottom: 17.5px;
            border-bottom: 1px solid $medium-gray;

            &.active {
                & ~ .panel {
                    display: flex;
                }
                .toggle {
                    transform: rotate(-180deg);
                }
            }

            .h5 {
                transition: all $timming ease-in-out;
                font-weight: 400;
                pointer-events: none;
                @include rwd(600){
                    @include font-size(20)
                }
            }

            .--icon-circle {
                height: 28px;
                width: 28px;
          
                &.toggle {
                    border: 1px solid #d7e7e1;
                    background-color: $green;
                    color: $white;
                }
            }

            &:hover {
                .h5 {
                    color: $green;
                }
            }

            &.active {
                .h5 {
                    color: $green;
                }
            }
        }

        .panel {
            display: none;
            flex-direction: column;
            overflow: hidden;
            padding-top: 40px;

            .btn-remove-link {
                display: none;
                &.--remove-all-sp {
                    display: inline-block;
                    @include font-size(14, 19, 700);
                    color: $dark-gray;
                }
            }
            @include rwd(600) {
                .btn-remove-link {
                    display: inline-block;
                    @include font-size(14, 19, 700);
                    color: $dark-gray;
                    &.--remove-all-sp {
                        @include font-size(14, 19, 700);
                        padding: 16px 24px;
                        min-height: 48px;
                        border-radius: 8px;
                        border: 1px solid $light-gray;
                    }
                }
            }

            .items {
                max-height: 43vh;
                overflow: auto;
            }

            &__container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                padding-bottom: 20px;
                margin-bottom: 1rem;
                border-bottom: 1px solid #e7e7e7;

                @include rwd(600) {
                    flex-direction: column;
                    gap: 20px;

                    .btn-remove {
                        display: none
                    }
                }

                .quantity {
                    display: flex;
                    height: 48px;
                    border-radius: 8px;
                    background-color: $light-green-50;
                    padding: 24px 14px;
                    align-items: center;

                    span {
                        margin: 14.5px;
                        @include font-size(14, 19.1, 700);
                    }

                    svg {
                        stroke: $green;
                        pointer-events: none;
                        vertical-align: middle;
                    }

                }
                .btn-remove {
                    margin-right: 12px;
                }
            }

            &__quantity {
               
                @include rwd(600) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
            }

            &__content {
                display: flex;
                align-items: center;
                // width: max-content;
                width: 100%;

                svg {
                    stroke: $green;
                    pointer-events: none;
                    vertical-align: middle;
                    height: 19px;
                    width: 19px;
                }
                
            }

            &__image {
                padding: 5.5px;
                background-color: $white;
                border: 1px solid #e7e7e7;
                border-radius: 4px;
                height: 65px;
                width: 65px;

                img {
                    width: 54px;
                    height: 54px;
                    object-fit: contain;
                }
            }

            &__text {
                margin-left: 40px;
                max-width: 858px;

                .title {
                    @include font-size(18, 24.55, 700);
                }

                .--text-xs {
                    line-height: 19.1px;
                    color: $dark-gray;
                    margin-top: 4px;
                }
            }

            &__footer-buttons {
                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
    .product_quantity {
        width: 30px;
        border: 0;
        background-color: transparent;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        color: $black;
        pointer-events: none;
    }
}
