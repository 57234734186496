.cta-registration {
    // margin-top: 90px;
    // margin-bottom: 140px;

    &__container {
        border: 1px solid $medium-gray;
        border-radius: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 105px;
        padding-bottom: 105px;
    }

    &__content {
        display: flex;
        max-width: 869px;
        margin-right: auto;
        margin-left: auto;

        .btn {
            height: max-content;
            align-self: center;
            margin-left: 30px;
        }
    }

    &__text {
        margin-left: 46px;

        .h5 {
            color: $green;
            @include font-size(34, 30, 700);
        }
        .subtitle {
            font-weight: 400;
            color: $black;
            @include font-size(24, 30, 500);
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .--text-xs {
            max-width: 470px;
        }
    }

    @include device-lg {
        padding-top: 54px;
        padding-bottom: 77px;

        &__container {
            flex-direction: column;
        }

        &__content {
            flex-direction: column;
            align-items: center;
        }

        &__text {
            margin-top: 24px;
            margin-left: 0;
            
            .h5 {
                text-align: center;
            }

            .subtitle {
                margin-bottom: 24px;
            }

            .--text-xs {
                margin-bottom: 24px;
                text-align: center;
            }
        }
    }

    @include device-md {
        padding-top: 60px;
        padding-bottom: 60px;
        margin-top: 0;
        margin-bottom: 0;

        &__container {
            border: none;
            padding-top: 0;
            padding-bottom: 0;
        }

        &__text {
            .h5 {
                text-align: left;
                @include font-size(32, 31, 700);
                margin-left: 61px;
                min-height: 60px;
            }

            .subtitle {
                margin-bottom: 24px;
                margin-left: 0;
                @include font-size(24, 30, 500);
                min-height: max-content;
            }

            .--text-xs {
                margin-bottom: 24px;
                text-align: left;
            }
        }

        &__content {
            position: relative;

            svg {
                width: 45px;
                left: 0;
                position: absolute;
                top: 10px;
            }

            .btn {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}