.dropdown-download {
    .h4 {
        font-weight: 400;
        letter-spacing: 0;

        strong {
            color: $green;
        }
    }

    .--text-m {
        font-weight: 400;
    }

    &-container {
        margin-top: 24px;
    }

    &-list-box {
        padding-inline-start: 40px;
        padding-block-start: 20px;
    }

    &-list {
        margin-top: 0;
        &__item {
            padding-block: 20px;
            border-bottom: 1px solid $medium-gray;
            display: flex;
            justify-content: space-between;
            gap: 20px;
            align-items: center
        }
    }

    @include device-md {
        .--text-m {
            @include font-size(18, 24.6, 400, 0);
        }
    }
}
