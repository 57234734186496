.form {
    &__field {

        .form__error,
        .form__success {
            display: none;
        }

        &.--input,
        &.--select,
        &.--textarea,
        &.--code-and-phone {
            width: 257px;
            position: relative;
            background-color: transparent;
            z-index: 4;

            &.--required {
                .form__label:after {
                    content: "*";
                    color: $black;
                    font-size: 12px;
                    padding-left: 1.5px;
                }
            }

            .caret {
                position: absolute;
                z-index: 10;
                color: $green;
                display: initial !important;
                top: 50%;
                right: 0.75rem;
                /* Ajustar la posición de la flecha */
                transform: translateY(-50%);
                pointer-events: none;
                stroke-width: 1px;
                width: 20px;
                // transition: all $timming ease-in-out;
            }

            .form__control {
                outline: transparent;
                display: block;
                position: relative;
                z-index: 0;
                border: 1px solid $medium-gray;
                background-color: $white;
                border-radius: 8px;
                padding: 12px;
                height: 44px;
                width: 100%;
                font-family: "Nunito", sans-serif;
                @include font-size(16, 20, 400);
                transition: all $timming ease-in-out;
                color: $black;

                &:autofill,
                &:autofill:hover,
                &:autofill:focus {
                    box-shadow: 0 0 0px 1000px #fff inset;
                    border-color: $green;
                }

                &:hover:not(:disabled) {
                    border-color: $green;
                }

                &:focus {
                    border-color: $black !important;
                    outline: none;
                    color: $black !important;

                    &~.caret {
                        color: $black;
                        // transform: translateY(-50%) rotate(-180deg);
                        // transition: all $timming ease-in-out;
                    }
                }

                &:focus+.form__label,
                &:valid+.form__label {
                    @extend .active;
                }

            }

            &.--select {
                .form__control {
                    padding-right: 36px !important;
                    -webkit-appearance: none;
                    /* Quitar la apariencia nativa del select */
                    appearance: none;
                }
            }

            .form__label {
                position: absolute;
                top: 12px;
                /* Ajustar la posición del label */
                left: 12px;
                pointer-events: none;
                transition: 0.2s ease-in-out;
                color: #999;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 137.5% */
                letter-spacing: -0.32px;

                &.active {
                    top: -0.65rem;
                    /* Mover el label por encima del input */
                    font-size: 0.75rem;
                    color: $black;
                    background-color: $white;
                    /* Agregar un fondo blanco al label */
                    padding: 0 0.25rem;
                    /* Añadir espacio alrededor del texto del label */
                }
            }

            .form__alert {
                display: flex;
                color: $dark-gray;
                @include font-size(10, 12, 700, 0);
                margin-top: 8px;
                align-items: center;
                padding-left: 8px;
                position: absolute;
                bottom: -20px;

                // &#contactPhoneInputAlert {
                //     display: none;
                // }

                svg {
                    position: inherit;
                    color: $danger;
                    margin-right: 4px;
                }
            }

            svg:not(.caret) {
                display: none;
            }

            &.--icon {
                .form__control {
                    padding-left: 36px;
                }

                svg {
                    display: block;
                    position: absolute;
                    z-index: 2;
                    top: 12px;
                    left: 12px;
                    color: #999999;
                }
            }

            &.--filled {
                svg {
                    color: $black;
                }

                .form__label {
                    @extend .active;
                }
            }

            &.--has-error {
                .form__control {
                    border-color: $danger;
                }

                .form__error {
                    display: flex;
                    color: $danger;
                    @include font-size(12, 16.37, 700, 0);
                    margin-top: 8px;
                    align-items: center;
                    padding-left: 8px;
                    position: absolute;
                    bottom: -20px;

                    svg {
                        position: inherit;
                        color: $danger;
                        margin-right: 4px;
                    }
                }

                .form__alert {
                    display: none !important;
                }

                svg {
                    color: $black;
                }
            }

            &.--success {
                .form__control {
                    border-color: $green;
                    background-color: $light-green-50;
                }

                .form__label {
                    color: $black;
                }

                .form__success {
                    display: flex;
                    color: $green;
                    @include font-size(12, 16.37, 700, 0);
                    margin-top: 8px;
                    align-items: center;

                    svg {
                        position: inherit;
                        color: $green;
                        margin-right: 4px;
                    }
                }

                svg {
                    color: $black;
                }
            }

            &.--disabled {
                .form__control {
                    background: linear-gradient(0deg,
                            rgba(0, 0, 0, 0.05) 0%,
                            rgba(0, 0, 0, 0.05) 100%),
                        #fff;
                    pointer-events: none;
                }

                .form__label::after {
                    color: #999;
                }

                .caret {
                    color: #999;
                }
            }

            .form__control[disabled] {
                background: linear-gradient(0deg,
                        rgba(0, 0, 0, 0.05) 0%,
                        rgba(0, 0, 0, 0.05) 100%),
                    #fff;
                pointer-events: none;

                &~.form__label::after {
                    color: #999;
                }

                &~.caret {
                    color: #999;
                }
            }
        }

        &.--code-and-phone {
            .code-area {
                pointer-events: none;
                font-family: "Nunito", sans-serif;
                border: 0;
                position: absolute;
                width: 45px;
                z-index: 4;
                background: transparent;
                font-size: 16px;
                line-height: 22px;
                top: 11px;
                left: 12px;
            }

            .form__control {
                padding-left: 45px;

                &:focus+.form__label .form__label__tel,
                &:valid+.form__label .form__label__tel {
                    display: initial;
                }

                &:focus+.form__label .form__label__zeros,
                &:valid+.form__label .form__label__zeros {
                    display: none;
                }
            }

            .form__label {
                padding-left: 33px;

                &__tel {
                    display: none;
                }
            }

            // &:focus {
            //     .form__label {
            //         @extend .active;
            //         &__tel {
            //             display: inline-block;
            //         }
            //         &__zeros {
            //             display: none;
            //         }
            //     }
            // }
        }

        &.--textarea {
            .form__control {
                width: 342px;
                height: 149px;
                resize: none;
            }
        }

        &.--checkbox,
        .--checkbox {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            .form__label {
                pointer-events: all;
                position: static;
                padding: 0;
                @include font-size(16, 24);
            }

            .form__control {
                appearance: none;
                background-color: $white;
                margin: 0 6px 0 0;
                color: currentColor;
                border: 1px solid $black;
                transform: translateY(-0.075em);
                display: inline-grid;
                place-content: center;
                vertical-align: middle;
                transition: all $timming ease-in-out;
                width: 16px;
                height: 16px;
                border-radius: 4px;

                &.--big {
                    width: 16px;
                    height: 16px;
                    border-radius: 4px;

                    &::before {
                        width: 0.8em;
                        height: 0.8em;
                    }
                }

                &.--small {
                    width: 14px;
                    height: 14px;
                    border-radius: 3.5px;

                    &::before {
                        width: 0.6em;
                        height: 0.6em;
                    }
                }

                &::before {
                    content: "";
                    transform: scale(0);
                    box-shadow: inset 1em 1em $green;
                    background-color: CanvasText;
                    clip-path: polygon(18% 41%,
                            5% 55%,
                            37% 84%,
                            100% 20%,
                            87% 7%,
                            37% 58%);
                    transition: all $timming ease-in-out;
                    width: 0.8em;
                    height: 0.8em;
                }

                &:hover {
                    border: 1px solid $green;
                    background-color: $light-green-50;
                    cursor: pointer;
                }

                &:checked {
                    background-color: $light-green-50;
                    border-color: $green;

                    &::before {
                        transform: scale(1);
                    }
                }
            }

            &.--disabled {
                .form__control {
                    background-color: $light-gray;
                    border-color: $dark-gray;
                    cursor: default;
                }

                .form__control:hover {
                    background-color: $light-gray;
                    border-color: $dark-gray;
                    cursor: default;
                }
            }
        }

        &.--checkboxGroup {
            position: relative;

            &.--has-error {
                .form__error {
                    display: flex;
                    color: $danger;
                    @include font-size(12, 16.37, 700, 0);
                    margin-top: 8px;
                    align-items: center;
                    // padding-left: 8px;
                    position: absolute;
                    bottom: -20px;
                }
            }
        }

        &.--radio-circle {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            .form__label {
                display: block;
                pointer-events: all;
                position: static;
                padding: 0;
                @include font-size(16, 24);
            }

            .form__control {
                appearance: none;
                background-color: $white;
                margin: 0 6px 0 0;
                color: currentColor;
                border: 1px solid $dark-gray;
                transform: translateY(-0.075em);
                display: inline-grid;
                place-content: center;
                vertical-align: middle;
                transition: all $timming ease-in-out;
                width: 16px;
                height: 16px;
                border-radius: 50%;

                &.--big {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                }

                &.--small {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;

                    &::before {
                        width: 6px;
                        height: 6px;
                    }
                }

                &::before {
                    content: "";
                    width: 0.6em;
                    height: 0.6em;
                    border-radius: 50%;
                    transform: scale(0);
                    background-color: $green;
                    transition: transform $timming ease-in-out;
                }

                &:hover {
                    border: 1px solid $green;
                    background-color: $light-green-50;
                    cursor: pointer;
                }

                &:checked {
                    background-color: $light-green-50;
                    border-color: $green;

                    &::before {
                        transform: scale(1);
                    }
                }
            }

            &.--disabled {
                .form__control {
                    background-color: $light-gray;
                    border-color: $dark-gray;
                    cursor: default;
                }

                .form__control:hover {
                    background-color: $light-gray;
                    border-color: $dark-gray;
                    cursor: default;
                }
            }
        }

        &.--radio-check {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            .form__label {
                display: block;
                pointer-events: all;
                position: static;
                padding: 0;
                @include font-size(16, 24);
            }

            .form__control {
                appearance: none;
                background-color: $white;
                margin: 0 6px 0 0;
                color: currentColor;
                border: 1px solid $dark-gray;
                transform: translateY(-0.075em);
                display: inline-grid;
                place-content: center;
                vertical-align: middle;
                transition: all $timming ease-in-out;
                width: 16px;
                height: 16px;
                border-radius: 50%;

                &.--big {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;

                    &::before {
                        width: 0.8em;
                        height: 0.8em;
                    }
                }

                &.--small {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;

                    &::before {
                        width: 0.6em;
                        height: 0.6em;
                    }
                }

                &::before {
                    content: "";
                    transform: scale(0);
                    box-shadow: inset 1em 1em $light-green-50;
                    background-color: CanvasText;
                    clip-path: polygon(18% 41%,
                            5% 55%,
                            37% 84%,
                            100% 20%,
                            87% 7%,
                            37% 58%);
                    transition: all $timming ease-in-out;
                    width: 0.8em;
                    height: 0.8em;
                }

                &:hover {
                    border: 1px solid $green;
                    background-color: $light-green-50;
                    cursor: pointer;
                }

                &:checked {
                    background-color: $green;
                    border-color: $green;

                    &::before {
                        transform: scale(1);
                    }
                }
            }

            &.--disabled {
                .form__control {
                    background-color: $light-gray;
                    border-color: $dark-gray;
                    cursor: default;
                }

                .form__control:hover {
                    background-color: $light-gray;
                    border-color: $dark-gray;
                    cursor: default;
                }
            }
        }

        &.--toggle-switch {

            .form__label,
            .form__label.--small {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 20px;

                .form__control {
                    display: none;

                    &:checked+.toggle-slider {
                        background-color: $green;
                    }

                    &:checked+.toggle-slider:before {
                        transform: translateX(16px);
                    }
                }

                .toggle-slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $light-gray;
                    border-radius: 12px;
                    transition: background-color 0.4s;

                    &:before {
                        position: absolute;
                        content: "";
                        width: 16px;
                        height: 16px;
                        left: 2px;
                        bottom: 2px;
                        background-color: $white;
                        border-radius: 50%;
                        transition: transform 0.4s;
                    }
                }

                &.--big {
                    width: 44px;
                    height: 24px;

                    .form__control {
                        &:checked+.toggle-slider:before {
                            transform: translateX(20px);
                        }
                    }

                    .toggle-slider {
                        &:before {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        &.--toggle-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-width: 170px;
            width: max-content;
            height: 32px;
            border-radius: 4px;
            background-color: transparent;
            border: 1px solid $green;

            .form__control {
                display: none;
            }

            .form__label,
            .form__label.--small {
                min-width: 85px;
                height: 100%;
                text-align: center;
                color: $green;
                cursor: pointer;
                @include font-size(14, 14, 700);
                display: flex;
                align-items: center;
                justify-content: center;

                &.--big {
                    min-width: 174px;
                }
            }

            .togle-text__label {
                width: 90%;
                display: flex;
                justify-content: center;
            }

            #option1:checked~label[for="option1"],
            #option2:checked~label[for="option2"],
            #option3:checked~label[for="option3"],
            #option4:checked~label[for="option4"],
            #option5:checked~label[for="option5"],
            #option6:checked~label[for="option6"] {
                background-color: $green;
                color: $white;
            }
        }
    }

    &__togle {
        width: min-content;

        .toggle-text__label {
            display: block;
            width: 90%;
            color: $green;
            @include font-size(12, 15, 400);
            text-align: center;
            margin-right: auto;
            margin-left: auto;
            margin-top: 10px;

            a {
                font-weight: 700;
            }
        }
    }

    &__footer {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        gap: 2rem;
        width: 100%;

        .placeholder {
            flex-grow: 1;
        }

        .btn {
            min-width: 170px;
            flex: 0 1 50%;
        }
    }
}