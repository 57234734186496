.cards-with-links {
    .title {
        padding-block-end: 48px;
        text-align: center;
        letter-spacing: -0.02em;

        @include font-size(32, 40);
        @include device-md {
            @include font-size(30, 36);
        }
    }
    &-grid {
        row-gap: 48px;
        @include main-grid();
        @include main-grid-columns(
            (
                'xl': 2,
                'lg': 2,
                'md': 1,
                'sm': 1,
            )
        );
        @include device-md{
            row-gap: 16px;
        }
    }

    .card-dash {
        width: 100%;
    }
}
