.cards-quotation {
    &__container {
        position: relative;

        @include main-grid();
        

        .title,
        .cards {
            grid-column: 1/8;
        }

        .title {
            @include device-md("min") {
                grid-column: 1/4;
            }
            
            @include device-lg("min") {
                grid-column: 1/8;
            }
            @include device-md {
                padding-bottom: 30px;
            }
        }

        .cards {
            @include device-md("min") {
                grid-column: 6/24;
            }

            @include device-lg("min") {
                grid-column: 11/24;
            }
        }

        &--bottom {
            margin-top: 4rem;
        }
    }

    h3 {
        margin-bottom: 3.5rem;
    }

    img {
        display: block;
        width: 100%;

        position: absolute;
        top: 50%;
        left: 0;

        @include device-md("min") {
            width: 35%;
        }
    }

    .cards {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .card-dash {
            width: auto;
            z-index: 1;
            box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.05),
                0px 2px 4px -2px rgba(16, 24, 40, 0.03);
        }
    }
}
