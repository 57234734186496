$menu-minify-bp: 1250;
$menu-collapse-bp: 1100;

.main-header {
    .header {
        position: fixed;
        inset: 0 0 auto 0;
        z-index: 50;
        display: block;
        border-top: 3px solid $green;
        border-bottom: 1px solid $medium-gray;
        height: 87px;
        background-color: $white;

        &__placeholder {
            display: block;
            height: 87px;
        }

        &__grid {
            @include rwd-min($menu-collapse-bp){

                align-items: center;
                height: 100%;
                transition: 500ms;
                display: grid;
                grid-template-columns: minmax(auto, 250px) 1fr auto minmax(auto, 200px);
                gap: 8px;
                > div {
                    transition: 300ms;
                }

                &:has(.header-search-bar.--open) {
                    grid-template-columns: minmax(auto, 250px) 0fr 1fr minmax(auto, 200px);
                }
                // height: 87px;
            }

            @include rwd($menu-collapse-bp) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                height: 100%;
            }


            .header__anchor {
                position: relative;
                display: flex;
                height: 45px;
                padding-left: 8px;
                padding-right: 8px;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                @include font-size(18, 18, 400);
                text-decoration: none;
                transition: color $timming ease-in-out;

                &::after {
                    content: '';
                    position: absolute;
                    inset: auto 8px 8px 8px;
                    width: 0%;
                    height: 1px;
                    border-bottom: 2px solid $green;
                    transition:
                        width $timming ease-in-out,
                        border-color $timming ease-in-out;
                }

                &:hover,
                &.active,
                &.--active {
                    color: $green;

                    &::after {
                        width: calc(100% - 16px);
                    }
                }

                &.--black {
                    background-color: $black;
                    color: $white;
                    transition: background-color $timming ease-in-out;
                    margin-left: 13px;

                    &::after {
                        display: none;
                    }

                    &:hover {
                        background-color: $orange;
                    }
                }

                &.--icon {
                    &::after {
                        display: none;
                    }
                }
            }

            // SUBMENU STYLES
            .header__nav {
                &--submenu-title {
                    cursor: default;
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    svg {
                        transition: all 0.3s ease;
                    }
                }
                &--submenu-container {
                    display: none;
                    padding-top: 10px;
                    position: absolute;
                    inset: 30px 0 0 0;
                   .submenu-items-container {
                    background-color: white;
                    border: 1px solid #D0D5DD;
                    border-radius: 8px;
                    box-shadow: 0px 12px 16px -4px rgba(34, 34, 34, 0.08), 0px 4px 6px -2px rgba(34, 34, 34, 0.03);
                    min-width: 140px;
                    width: fit-content;
                    padding: 6px;
                    .submenu-item {
                        a {
                            font-size: 16px;
                            text-decoration: none;
                            display: block;
                            padding: 8px;
                            color: black;
                            text-wrap: nowrap;
                            &:hover {
                                background-color: #E6F6ED;
                            }
                        }
                       
                    }
                   }
                }
                :hover {
                    .header__nav--submenu-container {
                        display: block;
                    }
                    .header__nav--submenu-title{
                        svg {
                            
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        &__logo {
            display: inline-flex;
            z-index: 5;

            svg {
                width: 244px;
                height: 19px;
            }
        }

        &-search-bar {
            height: 45px;
            &__container {
                display: grid;
                grid-template-columns: auto 0;
                overflow: hidden;
                align-items: center;
                // gap: 8px;
                width: 100%;
                overflow: hidden;
                transition: all 300ms;
                > *:has(:focus){
                    border-color: $green;
                }
            }
            &__input-container {
                display: grid;
                align-items: center;
                grid-template-columns: 20px 0 0;
                padding: 0px 12px;
                height: 45px;
                background: $white;
                border: 1px solid $medium-gray;
                border-radius: 8px;
                transition: all 500ms ease-in-out;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                    > svg {
                        stroke: $green;
                    }
                }
            }

            &__input-search {
                width: 20px;
                height: 20px;
            }

            &__input {
                border: 0;
                font-size: 16px;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.375;
                letter-spacing: -0.02em;
                color: #6d6d6d;
                transition: opacity 0.3s ease-in-out;
                height: 45px;
                margin-inline: 8px;
                opacity: 0;

                &:focus {
                    outline: none;
                    color: $black;
                }
            }

            &__input-close {
                margin-inline-start: 16px;
                cursor: pointer;
            }

            &__clean-value {
                cursor: pointer;
                width: 20px;
                height: 20px;
                stroke: #999999;
                display: none;
                &.--show {
                    display: unset;
                }
            }

            &__submit-close {
                display: grid;
                overflow: hidden;
                align-items: center;
                grid-template-columns: auto auto;
                position: relative;
                right: -100%;
                transition: all 0.25s;
                height: 100%;
            }



            &.--open {
                .header-search-bar__input {
                    opacity: 1;
                    transition: opacity 200ms 300ms ease-in-out;
                }
                .header-search-bar__input-container {
                    grid-template-columns: 20px 1fr 20px;
                    transition: all 500ms ease-in-out;
                    cursor: unset;
                    &:hover {
                        > svg {
                            stroke: $black;
                        }
                    }
                }

                .header-search-bar__container {
                    grid-template-columns: 1fr auto;
                }

                .header-search-bar__submit-close {
                    transition: all 0.25s ease-in-out;
                    right: 0;
                }
            }
        }

        &__nav {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            gap: 24px;

            .header__anchor {
                position: relative;
                display: flex;
                height: 45px;
                padding-left: 8px;
                padding-right: 8px;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                @include font-size(18, 18, 400);
                text-decoration: none;
                transition: color $timming ease-in-out;

                &::after {
                    content: '';
                    position: absolute;
                    inset: auto 8px 8px 8px;
                    width: 0%;
                    height: 1px;
                    border-bottom: 2px solid $green;
                    transition:
                        width $timming ease-in-out,
                        border-color $timming ease-in-out;
                }

                &:hover,
                &.active,
                &.--active {
                    color: $green;

                    &::after {
                        width: calc(100% - 16px);
                    }
                }

                &.--black {
                    background-color: $black;
                    color: $white;
                    transition: background-color $timming ease-in-out;
                    margin-left: 13px;

                    &::after {
                        display: none;
                    }

                    &:hover {
                        background-color: $orange;
                    }
                }

                &.--icon {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        &__burger {
            display: none;
            padding: 15px;
            cursor: pointer;
            transition-property: opacity, filter;
            transition-duration: 0.15s;
            transition-timing-function: linear;
            font: inherit;
            color: inherit;
            text-transform: none;
            background-color: transparent;
            border: 0;
            margin: 0;
            overflow: visible;
            transform: scale(0.5);
            transform-origin: center right;

            $burger: &;

            &.--active {
                #{$burger}-inner,
                #{$burger}-inner::before,
                #{$burger}-inner::after {
                    background-color: #000;
                }

                #{$burger}-inner {
                    transform: translate3d(0, -10px, 0) rotate(-45deg);
                    transition-delay: 0.22s;
                    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                }
                #{$burger}-inner::after {
                    top: 0;
                    opacity: 0;
                    transition:
                        top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                        opacity 0.1s 0.22s linear;
                }
                #{$burger}-inner::before {
                    top: 0;
                    transform: rotate(-90deg);
                    transition:
                        top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }

            &-box {
                width: 40px;
                height: 24px;
                display: inline-block;
                position: relative;
            }

            &-inner {
                display: block;
                margin-top: -2px;
                top: auto;
                bottom: 0;
                transition-duration: 0.13s;
                transition-delay: 0.13s;
                transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                background-color: deeppink;
            }

            &-inner,
            &-inner::before,
            &-inner::after {
                width: 40px;
                height: 4px;
                background-color: #000;
                border-radius: 4px;
                position: absolute;
                transition-property: transform;
                transition-duration: 0.15s;
                transition-timing-function: ease;
            }

            &-inner::before,
            &-inner::after {
                content: '';
                display: block;
            }

            &-inner::before {
                top: -10px;
                transition:
                    top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            &-inner::after {
                bottom: -10px;
                top: -20px;
                transition:
                    top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    opacity 0.1s linear;
            }
        }

        &__menu-button {
            display: none;

            .header-toggler {
                font-weight: 700;
                position: relative;
                z-index: 3;
                cursor: pointer;
                border: none;
                background: none;
                padding: 0;
                margin: 0;
                margin-left: 1.5rem;
                display: flex;
                align-items: center;

                &:hover,
                &:focus,
                &:active {
                    outline: none;
                }

                .toggler-text {
                    font-size: 0.7rem;
                    color: $black;

                    &:before,
                    &:after {
                        transition: all 0.4s ease-in-out;
                    }

                    &:before {
                        content: '';
                        visibility: visible;
                        opacity: 1;
                        position: absolute;
                    }

                    &:after {
                        content: '';
                        visibility: hidden;
                        opacity: 0;
                    }
                }

                .toggler-icon {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    margin-left: 0.5rem;
                    transform: rotate(0deg);
                    transition: 0.5s ease-in-out;

                    span {
                        display: block;
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        background: $black;
                        border-radius: 5px;
                        opacity: 1;
                        left: 0;
                        transform: rotate(0deg);
                        transition: 0.25s ease-in-out;

                        &:nth-child(1) {
                            top: 3px;
                        }

                        &:nth-child(2) {
                            top: 8.5px;
                            height: 2.5px;
                        }

                        &:nth-child(3) {
                            top: 15px;
                            height: 2px;
                        }
                    }
                }

                &.active {
                    .toggler-text {
                        &:before {
                            visibility: hidden;
                            opacity: 0;
                        }

                        &:after {
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    .toggler-icon {
                        span {
                            &:nth-child(1) {
                                top: 8.5px;
                                transform: rotate(135deg);
                            }

                            &:nth-child(2) {
                                opacity: 0;
                            }

                            &:nth-child(3) {
                                top: 8.5px;
                                transform: rotate(-135deg);
                            }
                        }
                    }
                }
            }
        }

        @include rwd($menu-collapse-bp) {
            height: 61px;

            &__nav,
            &-sg,
            &-search-bar {
                display: none !important;
            }

            &__burger {
                display: inline-block;
            }

            &__placeholder {
                height: 61px;
            }

            &__menu-button {
                display: block;

                .header-toggler {
                    .toggler-text {
                        display: none;
                    }

                    &.active {
                        .toggler-text {
                            display: block;
                        }
                    }
                }
            }
        }

        // FRIST BREAKPOINT
        @include rwd($menu-minify-bp) {
            &__grid {
                grid-template-columns: minmax(auto, 205px) 1fr auto minmax(auto, 110px);

                &:has(.header-search-bar.--open) {
                    grid-template-columns: minmax(auto, 205px) 0fr 1fr minmax(auto, 110px);
                }
            }

            &__logo {
                svg {
                    width: 203px;
                    height: 16px;
                }
            }

            &__nav {
                gap: 6px;
                justify-content: flex-end;
            }

            .header-sg {
                width: 110px;
            }
        }

        @include rwd($menu-collapse-bp) {
            &__logo {
                svg {
                    width: 192px;
                    height: 14px;
                }
            }
        }
    }
    &.--whatsapp {
        .header__grid {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .btn-whatsapp {
            @include device-sm {
                padding: 8px;
                border-radius: 40px;
                min-height: unset;
                .btn-label {
                    display: none;
                }
            }
        }
    }
    &.--survey {
        .header{
            &__progress {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                column-gap: 16px;
                grid-column: 2/-1;
                width: 100%;
                &-bar {
                    width: 400px;
                    height: 6px;
                    background-color: $medium-gray;
                    border-radius: 8px;
                    &__fill {
                        height: 6px;
                        border-radius: 8px;
                        background-color: $green;
                        width: 0%;
                    }
                }
                &-score {
                    display: flex;
                }
            }
        }
        @include rwd(768){
            .header{
                height: 102px;
                &__placeholder {
                    height: 102px;
                }
                &__grid {
                    flex-direction: column;
                    justify-content: center;
                    row-gap: 16px;
                }
                &__progress{
                    &-bar{
                        width: 100%;
                    }
                }
            }
        }
    }
}
