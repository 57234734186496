.cards-subcategories {

    &__top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
        
        @include device-md {
            padding-right: 16px;
        }        
        .--title-s {
            margin-bottom: 0;
            font-weight: 400 !important;
            @include device-md("min"){
                @include font-size(32);
                line-height: 1.25;
            }
        }
    }

    .swiper-wrapper {
        padding-top: 40px;
    }

    &__subtitle {
        @include device-md {
            padding-right: 16px;
        }
    }

    &__cards {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        .card-subcategory {
            margin-right: auto !important;
            margin-left: auto;
            height: 100%;
        }
    }

    &__item {
        height: auto !important;
    }

    &__nav-pag {
        position: relative;
        margin-top: 40px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        gap: 20px;

        .swiper-button-next::after,
        .swiper-button-prev::after {
            font-size: 16px;
            color: $black;
            margin: 0;
            @include font-size(14, 19, 700, 0);
        }
        
        .swiper-button-next,
        .swiper-button-prev {
            position: initial !important;
            border: 1px solid #6F6E6F;
            max-width: 42px;
            max-height: 42px;
            min-width: 42px;
            min-height: 42px;
            border-radius: 50%;
        }
    }

    @include device-md {
        padding-bottom: 20px;
        padding-right: 0;

        &__top-container {     
            .cards-subcategories__nav-pag {
                display: none;
            }
        }

        &__cards {
            flex-direction: column;
            align-items: center;

            .card-subcategory {
                margin-right: 0;
            }
        }

        .--text-body-l {
            @include font-size(20, 27, 400, 0);
        }
    }
}