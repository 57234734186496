.text-img-vertical {
    .h4 {
        margin-bottom: 36px;

        @include device-md("min") {
            text-align: center;
        }
    }

    &__container {
        display: flex;
        justify-content: center;

        &.--desktop.--grid-cols-3 {
            display: none;

            @include device-lg("min") {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;
            }

            .text-img-vertical__item {
                margin-right: 0 !important;
            }
        }

        &__mobile {
            display: none;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 28px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__text {
        max-width: 348px;

        .h5 {
            margin-bottom: 24px;
            color: $green;
        }

        .text {
            font-weight: 400;
            font-size: 16px;

            p {
                margin-bottom: 10px;
                @include font-size(16, 21.82, 400, -2)
            }
        }
    }

    &__image {
        background-color: $medium-gray;
        width: 100%;
        height: 230px;
        object-fit: cover;
        margin-bottom: 24px;
        border-radius: 16px;
    }

    @include rwd(1220) {
        max-width: 100vw;

        &__container {
            display: none;
        }

        &__image {
            width: 100%;
            max-width: 348px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            object-fit: cover;
        }

        &__text {
            margin-left: auto;
            margin-right: auto;
        }

        &__container__mobile {
            display: block;

            .swiper {
                padding-bottom: 60px;

                .swiper-slide {
                    margin-right: 0;
                }

                .swiper-pagination-bullet-active {
                    background-color: $green;
                }
            }
        }
    }

    @include device-md {
        margin-left: 0;

        &__image {
            max-width: 100%;
        }
    }

    &.--two-items {
        background-color: $light-gray-25;
    }

}
