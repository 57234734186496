.text-only {
    
    .h2 {
        font-weight: 400;
        max-width: 825px;
        margin-bottom: 34px;
    }

    &__container {
        // display: flex;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;

        &__mobile {
            display: none;
        }
    }
    
    &__item {
        // width: 310px;
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .h5 {
            letter-spacing: 0;
            color: $green;
            margin-bottom: 16px;
        }

        .btn {
            margin-top: 40px;
            min-width: 190px;
            width: max-content;

            &.--big {
                min-width: 190px;
            }
            
            @include device-md {
                width: 100%;
            }
        }
    }
    
    @include device-md {
        max-width: 100vw;
        
        .h2 {
            @include font-size(32, 40, 400, -2);
            margin-bottom: 10px;
        }

        &__container {
            display: none;
        }

        &__container__mobile {
            display: block;

            .swiper {
                padding-bottom: 60px;

                .swiper-pagination-bullet-active {
                    background-color: $green;
                }
            }
        }
    }

    @include device-md {
        margin-left: 0;
    }
}