.main-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black;
    color: $white;
    min-height: 80px;
    @include font-size(12, 12);

    &__grid {
        @include flex-center;
        justify-content: space-between;
        height: 100%;

        &__left-column {
            @include flex-center;
            gap: 8vw;

            a:hover {
                color: $white;
            }
        }

        &__right-column {
            a {
                position: relative;
    
                &::after {
                    content: "";
                    position: absolute;
                    inset: auto auto -4px auto;
                    width: 0%;
                    height: 1px;
                    border-bottom: 1px solid $white;
                    transition: width $timming ease-in-out,
                        border-color $timming ease-in-out;
                }
    
                &:last-of-type {
                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                }
        
                &:hover {
                    color: $white;
        
                    &::after {
                        width: calc(100% - 12px);
                    }
                }
            }
        }
    }

    &__social {
        @include flex-start(row);
        align-items: center;
        gap: 20px;

        &-link {
            display: block;
            line-height: 1;
            transition: color $timming ease-in-out;
        }
        .social-icon {
            stroke-width: 0;
        }
    }

    &__phone {
        text-decoration: none;
        transition: color $timming ease-in-out;
        display: flex;
        align-items: center;

        .icon {
            height: 0.8em;
            width: 0.8em;
            margin-right: 10px;
        }
    }

    &__nav {
        @include flex-center;

        &__link {
            text-decoration: none;
            transition: color $timming ease-in-out;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                border-right: 1px solid $white;
                padding-right: 12px;
                margin-left: 12px;
            }

            &:last-child {
                margin-left: 12px;
            }
        }
    }

    @include device-lg {
        min-height: auto;
        padding: 20px 0;
        &__grid {
            &__left-column {
                flex-direction: column;
                gap: 20px;

                .main-footer__social {
                    order: 2;
                }

                .main-footer__phone {
                    order: 1;
                }
            }
        }
    }

    @include device-md {
        &__grid {
            padding-left: 0 !important;
            padding-right: 0 !important;
            flex-direction: column;
            justify-content: center;
            gap: 25px;

            &__left-column {
                width: 100%;
                padding: 0 20px;
            }
            &__right-column {
                width: 100%;
                padding: 25px 20px 0;
                border-top: 1px solid $dark-gray;
            }
        }

        &__nav {
            flex-direction: column;
            justify-content: center;
            gap: 15px;
            width: 100%;

            &__link {
                border-right: none !important;
                padding: 0;
                margin: 0;
                width: 100%;
                justify-content: center;
                text-align: center;
            }
        }
    }
}
