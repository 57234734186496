.slider-with-images {
    background-color: $green;
    padding-top: 80px;
    padding-bottom: 46px;

    .wrapper {
        overflow: hidden;
    }

    &__title {
        margin-bottom: 10px;
        color: $white;
    }

    &__swiper {
        padding-bottom: 34px !important;
        padding-top: 30px !important;

        .swiper-slide {
            height: auto;
        }
    }

    &__item {
        display: grid !important;
        grid-template-columns: 0.4fr 0.6fr;
        border-radius: 10px;
        background-color: $white;
        overflow: hidden;
        width: 100%;
        min-height: 452px;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__text {
        padding: 40px;

        .h4 {
            font-weight: 400;
            color: $green;
            margin-bottom: 8px;
        }

        .text {
            p {
                @include font-size(16, 22, 300, -2);
                margin-bottom: 10px;
            }
        }

        .tag {
            display: flex;
            align-items: center;
            color: $green;
            margin-bottom: 20px;

            .--icon-circle {
                max-width: 28px;
                min-width: 28px;
                max-height: 28px;
                min-height: 28px;
            }

            p {
                margin-left: 8px;
            }
        }
    }

    &__nav-pag {
        position: relative;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 85px;

        .swiper-button-next::after,
        .swiper-button-prev::after {
            font-size: 18px;
            color: $black;
            margin: 0;
            @include font-size(14, 19, 700, 0);
            color: $white;
        }
        
        .swiper-button-next,
        .swiper-button-prev {
            position: initial !important;
            margin-top: 1px;
        }

        .swiper-pagination {
            position: initial !important;
            margin: 0;
            @include font-size(14, 19, 700, 0);
            color: $white;
        }
    }

    .svg-bg {
        position: absolute;
        right: 30px;
        bottom: 60px;
    }

    @include device-md {
        padding-top: 40px;
        padding-bottom: 40px;
        
        &__item {
            display: flex !important;
            flex-direction: column;
            min-height: 0;
        }

        &__nav-pag {
            position: absolute;
            left: 0;
        }

        &__image {
            object-fit: contain;
            height: auto;
        }

        
    }

    @include device-lg {
        .svg-bg {
            right: 0;
        }
    }

    @include rwd(850) {
        .svg-bg {
            right: auto;
            left: 120px;
            bottom: 0;
        }
    }
}