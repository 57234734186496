.card-features {
    width: 255px;
    min-height: 255px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid $medium-gray;
    text-decoration: none;
    padding: 20px;

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .--icon-circle {
            width: 32px;
            height: 32px;
        }

        .--text-m {
            line-height: 22px;
            letter-spacing: -0.02em;
            margin-left: 12px;
            width: 171px;
        }
    }

    &__description {
        margin-top: 15px;
    }
}