.banner-form-landings {
    position: relative;
    margin-right: auto;
    margin-left: auto;

    padding-top: 0;
    padding-bottom: 0;

    &__container {
        color: $white;
        position: relative;
        z-index: 2;
        height: 355px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 28px 0px;
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        
        .title {
            @include font-size(40, 50, 700, -2);
            margin-bottom: 8px;
            max-width: 464px;
        }

        .subtitle {
            @include font-size(18, 28, 400, 0);
            max-width: 464px;
        }

        .btn {
            background: #F7931D;
            border-radius: 30px;
            max-width: 464px;
            color: $white;
            margin-top: 30px;
            &:hover {
                background: $green;
                transition: background 0.2s ease-out;
                border-color: transparent;
            }
        }
    }

    &.left {
        &.gray {
            .banner-form-landings__container {
                background: linear-gradient(-90deg, #2d2d2d 52.75%, rgba(0, 0, 0, 0) 74.22%);
            }
        }

        &.green {
            .banner-form-landings__container {
                background: linear-gradient(-89.91deg, #035C3C 51.56%, rgba(0, 0, 0, 0) 74.01%);
            }
        }

        .banner-form-landings__content {
            .title, .subtitle, .btn {
                grid-column: 2;
                margin-left: 40px;
            }
        }

        .banner-form-landings__image {
            height: 355px;
            width: 50%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &.right {
        &.gray {
            .banner-form-landings__container {
                background: linear-gradient(90deg, #2d2d2d 52.75%, rgba(0, 0, 0, 0) 74.22%);
            }
        }

        &.green {
            .banner-form-landings__container {
                background: linear-gradient(89.91deg, #035C3C 51.56%, rgba(0, 0, 0, 0) 74.01%);
            }
        }

        .banner-form-landings__content {
            .title, .subtitle, .btn {
                grid-column: 1;
            }
        }

        .banner-form-landings__image {
            height: 355px;
            width: 50%;
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    @include rwd(800) {
        &.right, &.left {
            &.gray {
                .banner-form-landings__container {
                    background-color: #2d2d2d;
                    padding-left: 0;
                    align-items: flex-start;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    height: 100%;
                    padding: 2rem 0 4rem 0;

                    .banner-form-landings__content {
                        .title, .subtitle, .btn {
                            margin-left: 0px;
                        }
                    }
                }
            }

            &.green {
                .banner-form-landings__container {
                    background-color: #035C3C;
                    padding-left: 0;
                    align-items: flex-start;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    height: 100%;
                    padding: 2rem 0 4rem 0;
                }
            }

            .banner-form-landings__image {
                display: none;
            }
        }

        &__content {
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;

            .title, .subtitle {
                max-width: none;
            }
        }
    }
}
