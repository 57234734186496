.hero-full-background-image {
    background-position: center;
    background-size: cover;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(45, 45, 45, 0.7);
        z-index: 1;
    }
    &__container {
        z-index: 2;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;
        max-width: 635px;

        @include device-md {
            padding-top: 37px;
            padding-bottom: 22px;
            max-width: 550px;
        }
    }

    &__title {
        color: $white;
        @include font-size(56, 67, 400);
        margin-bottom: 24px;

        @include device-md {
            @include font-size(32, 38, 400);
            margin-bottom: 16px;
        }

        strong {
            font-weight: 700;
            color: $orange;
        }
    }

    &__subtitle {
        margin-bottom: 32px;
        color: $white;

        @include font-size(16, 19, 500);
        @include device-md {
            @include font-size(14, 17, 500);
            margin-bottom: 24px;
        }

        strong {
            font-weight: 700;
        }
    }
}
