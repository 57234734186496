.text-two-columns {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .h4 {
        max-width: 356px;
        margin-right: 16px;
    }

    li, p {
        max-width: 545px;
        list-style-type: disc !important;
        margin-bottom: 14px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &-right {
        font-size: 1rem;
    }
    @include device-lg {
        flex-direction: column;
        
        .h4 {
            margin-right: 0;
            margin-bottom: 16px;
        }
    }
}