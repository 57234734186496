.checkout {
    .selected-products {
        .title {
            text-transform: uppercase;
        }
    }
    &__header {
        a {
            text-decoration: none;
        }
    }
    &__grid {
        min-height: calc(100% - 300px);
        display: grid;
        grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
        grid-template-areas: "detail form";
        gap: 4rem;
        padding-bottom: 60px;

        .detail {
            grid-area: detail;
        }

        .form-checkout {
            grid-area: form;
        }

        .sp-checkout-is-empty {
            display: none;
        }

        &.--no-detail {
            .sp-checkout-is-empty {
                display: block;
            }
            .sp-checkout-has-products {
                display: none;
            }
        }

        @include device-lg("max") {
            grid-template-columns: 1fr;
            grid-template-areas: "detail" "form";
            gap: 2rem;

            // &.--no-detail {
            //     grid-template-areas: "form";
            // }
        }

        .h5 {
            margin-bottom: 20px;
        }

        .sp-checkout-is-empty {
            &__main-msg {
                @include font-size(18, 24, 600);
                color: $black;
                padding: 16px 0;
            }
            &__footer {
                p {
                    margin-bottom: 12px;
                    max-width: 400px;
                }
                .btn {
                    max-width: fit-content;
                }
            }
        }
    }
}
