@use "sass:math";

.--component-pt {
    padding-top: 2.5rem !important;

    @include device-md("min") {
        padding-top: 3.75rem !important;
    }
}

.--component-pb {
    padding-bottom: 2.5rem !important;

    @include device-md("min") {
        padding-bottom: 3.75rem !important;
    }
}

.--px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}