.slider-three-slides {
    display: flex;
    flex-direction: column;
        
    h5 {
        margin-bottom: 12px;
        letter-spacing: 0;
    }

    &__title {
        @include font-size(48, 53.5, 700, -2);
        color: $green;
        margin-bottom: 33px;
        text-align: center;
    }


    &__swiper {
        padding-bottom: 40px !important;
        width: 100%;
        
        .swiper-pagination-bullet-active {
            background-color: $green;
        }
        
        .swiper-pagination-horizontal {
            bottom: 0px !important;
        }

        .swiper-button-next, .swiper-button-prev {
            width: 42px;
            height: 42px;
            background: rgba(45, 45, 45, 0.85);
            border-radius: 50%;

            &::after {
                font-size: 12px;
                color: $white;
            }
        }

        .swiper-button-next {
            margin-right: 13px;
        }

        .swiper-button-prev {
            margin-left: 13px;
        }
    }

    &__item {
        border-radius: 16px;
        overflow: hidden;

        img {
            max-width: 100%;
            width: 100%;
            display: block;
            aspect-ratio: 345 / 373;
            object-fit: cover;
            border-radius: 16px;
        }
    }

    @include device-lg {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 19px;

        &__container {
            h5 {
                margin-bottom: 7px;
                max-width: 100%;
            }
    
            .--text-body-xs {
                max-width: 100%;
            }
        }
    
        &__swiper {
            margin-left: 0 !important;
            max-width: none;
            max-height: none;
            width: 100%;

            .swiper-button-next, .swiper-button-prev {
                width: 32px;
                height: 32px;
            }
        }

        &__title {
            margin-bottom: 20px;
            max-width: 100%;
        }
    }
    
    @include device-md {
        &__title {
            @include font-size(24, 30, 700, 0);
        }

        &__container {
            h5 {
                @include font-size(18, 28, 700, 0);
            }
        }
    }
}