.other-projects {
    .arg {
        background-color: $white;
        padding-bottom: 80px;
        // padding-top: 80px;
    }

    .br {
        background-color: #EBF8F3;
        padding-top: 80px;
        padding-bottom: 80px;

        .other-projects__country {
            align-self: center;
        }

        .--icon-circle {
            background-color: $white;
        }
    }

    .h4 {
        margin-bottom: 40px;
        font-weight: 400;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    &__country {
        grid-column: 1;
        @include font-size(32, 40, 300, -2);
        color: $dark-gray;
    }

    &__content {
        grid-column: 2 / -1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 40px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .--icon-circle {
            min-width: 28px;
            min-height: 28px;
            max-width: 28px;
            max-height: 28px;
        }

        .title {
            color: $green;
            @include font-size(24, 32.7, 400, 0);
            margin-bottom: 8px;
        }

        .subtitle {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            p {
                color: $green;
                text-transform: uppercase;
                @include font-size(16, 22, 700, -2);
                margin-left: 8px;
            }
        }

        .text {
            @include font-size(16, 22, 300, -2);
        }
    }

    @include device-lg {
        .br {
            .other-projects__country {
                align-self: flex-start;
            }
        }

        &__container {
            display: flex;
            flex-direction: column;
        }
        
        &__content {
            margin-top: 20px;
        }
    }

    @include device-md {
        .arg, .br {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }
}