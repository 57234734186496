.properties-filter {

    &-box {
        padding-block: 20px;
        @include device-md {
            padding-block: 24px;
        }
    }
    &-grid {
        row-gap: 12px;
        @include main-grid-v2($md-grid: true);
    }
    &__label {
        color: $dark-gray;
        @include font-size(16, 22, 600);
        @include main-grid-span-cols(
            (
                'lg': '1/8',
            )
        );
    }
    &__prop {
        position: sticky;
        right: 0;
        left: 0;
        background-color: $white;
        z-index: 10;
        outline: 1px solid $light-gray;
        width: auto !important;

        &:first-of-type {
            @include main-grid-span-cols(
                (
                    'lg': '9/14',
                    'md': '1/4',
                    'sm': '1/4',
                )
            );
        }

        &:last-of-type {
            @include main-grid-span-cols(
                (
                    'lg': '15/20',
                    'md': '5/8',
                    'sm': '5/-1',
                )
            );
        }

        &.--only-one {
            @include main-grid-span-cols(
                (
                    'lg': '15/20',
                    'md': '1/8',
                    'sm': '1/-1',
                )
            );
        }
    }
    &__search-btn {
        @include main-grid-span-cols(
            (
                'lg': '21/-1',
                'md': '9/-1',
            )
        );
    }
}
